import { CustomersAction, CustomersActions, CustomersData } from "../../reducers/customersReducer";
import { APIAccounts } from "../../api";
import { SearchCustomersContextProps } from "../../context/SearchCustomersProvider";

// eslint-disable-next-line import/prefer-default-export
export const getCustomer = async (
  searchCustomersContext: SearchCustomersContextProps,
  customerCount: number,
  hasExpired: () => void,
  customers: CustomersData,
  isIgnoreCheckAccess = false,
  dispatch: (cb: CustomersAction) => void,
  signal: AbortSignal,
  isExportCSV?: boolean
): Promise<CustomersData | null> => {
  const {
    limit,
    page,
    search,
    isShowLeads,
    relatedTables,
    sortBy,
    isShowActiveSubscription,
    isShowInactiveAccounts,
    isShowPassedEvaluations,
  } = searchCustomersContext;

  const data = {
    limit,
    page,
    contains: {
      ...search,
      email: search.email
        ?.replaceAll(",", " ")
        .split(" ")
        .filter((email: string) => !!email),
    },
    isShowLeads,
    relatedTables,
    sortBy,
    isShowActiveSubscription,
    isShowInactiveAccounts,
    isShowPassedEvaluations,
  };

  let accessToExportCsv = null;
  if (isExportCSV) {
    try {
      if (!isIgnoreCheckAccess) {
        accessToExportCsv = await APIAccounts.getAccountsCsvRequest();
      }

      data.page = 0;
      data.limit = customerCount.toString();
    } catch (error) {
      console.error("error getting permissions to export csv", error);
      hasExpired();
    }
  }

  try {
    dispatch(CustomersActions.fetchRequest());
    const response = await APIAccounts.getAccountsRequest(data, signal);

    const customersData: CustomersData = response.data;
    if (isIgnoreCheckAccess || (isExportCSV && accessToExportCsv?.data?.isCanExport)) {
      dispatch(CustomersActions.fetchSuccess(customers, sortBy));
      return customersData;
    }

    dispatch(CustomersActions.fetchRequest());
    dispatch(CustomersActions.fetchSuccess(customersData, sortBy));
  } catch (error) {
    const e = error as { code: string };
    if (e.code !== "ERR_CANCELED") {
      console.error("error fetching customers", e);
      dispatch(CustomersActions.fetchFailed());
      hasExpired();
    }
  }

  return null;
};
