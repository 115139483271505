import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { filesize } from "filesize";
import { IconButton, Button } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import DeleteIcon from "@material-ui/icons/Delete";

import { G_DRIVE_API_URLS } from "../../../../api/googleDrive";
import { APIGoogleDrive } from "../../../../api";
import useStyles from "./useStyles";

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

const DropZone = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const [files, setFiles] = useState<File[]>([]);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { isLoading, listOfUploadedFiles } = useSelector((state) => state.fundedTraderFiles);

  const handleUploadFiles = useMutation({
    mutationKey: [G_DRIVE_API_URLS.uploadCustomerFiles, id],
    mutationFn: async () => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("file", file);
      });
      await APIGoogleDrive.uploadCustomerFiles(id, formData);
    },
    onSuccess: () => {
      setFiles([]);
      fileRejections.splice(0, fileRejections.length);
      queryClient.invalidateQueries({ queryKey: [G_DRIVE_API_URLS.getCustomerFiles, id] });
    },
  });

  const isDisabled = listOfUploadedFiles.length >= 10 || isLoading;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uniqueFiles = acceptedFiles.filter(
        (file) => !files.some((existingFile) => existingFile.name === file.name)
      );
      setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    },
    [files]
  );

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/BMP": [],
    },
    disabled: isDisabled,
    maxFiles: 10 - listOfUploadedFiles.length,
    maxSize: MAX_FILE_SIZE,
    onError: (e) => console.log("e", e),
    preventDropOnDocument: false,
    multiple: true,
    onDrop,
  });

  const acceptedFileItems = files.map((file: File, index: number) => (
    <li key={`${file.name}_${index}`} className={classes.acceptedItem}>
      {file.name} Size: {filesize(file.size)}{" "}
      <IconButton
        className={classes.deleteAddedFileIcon}
        disabled={handleUploadFiles.isLoading}
        onClick={() => handleRemoveFile(index)}
      >
        <DeleteIcon className={classes.deletingIcon} fontSize="small" />
      </IconButton>
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {filesize(file.size)}
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="container">
      <div
        {...getRootProps({ className: cx(classes.container, { [classes.disabled]: isDisabled }) })}
      >
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
        <em>(Only *.pdf, *.jpeg, *.bmp files will be accepted)</em>
        <em>
          Maximum allowed amount of uploaded files are 10, uploaded {listOfUploadedFiles.length} /
          10
        </em>
      </div>

      {!!acceptedFileItems.length && (
        <aside>
          <h4>Accepted files</h4>
          <ul>{acceptedFileItems}</ul>
        </aside>
      )}

      {!!fileRejectionItems.length && (
        <aside>
          <h4>Rejected files</h4>
          <ul>{fileRejectionItems}</ul>
        </aside>
      )}

      {!!files.length && (
        <Button
          disabled={handleUploadFiles.isLoading}
          variant="contained"
          color="primary"
          onClick={() => handleUploadFiles.mutate()}
        >
          Upload
        </Button>
      )}
    </section>
  );
};

export default DropZone;
