import downloadCsv from "../../../../../utils/downloadCsv";
import { isWeekend } from "../../../../../utils";
import { ISalesStatsByDayResponse } from "../../../../../api/billing/index.types";

type TBuildReport = (
  report: ISalesStatsByDayResponse["data"]["report"],
  period: {
    startDate: string;
    endDate: string;
    isAllTime: boolean;
  },
  isIgnoreWeekends: boolean
) => void;
export const buildReport: TBuildReport = (report, period, isIgnoreWeekends) => {
  const data = [
    [
      "Date",
      "Total Net",
      "US Net",
      "Non-us Net",

      "TCP25K QT",
      "TC25K NT",
      "TC25K rebil",
      "TCP25K paid resets",
      "TCP25K free fresets",

      "TCP50K QT",
      "TCP50K NT",
      "TCP50K rebil",
      "TCP50K paid resets",
      "TCP50K free fresets",

      "TCP100K QT",
      "TCP100K NT",
      "TCP100K rebil",
      "TCP100K paid resets",
      "TCP100K free fresets",

      "GAU MINI 50K QT",
      "GAU MINI 50K NT",
      "GAU MINI 50K rebil",
      "GAU MINI 50K paid resets",
      "GAU MINI 50K free fresets",

      "GAU MINI 100 QT",
      "GAU MINI 100 NT",
      "GAU MINI 100 rebil",
      "GAU MINI 100 paid resets",
      "GAU MINI 100 free fresets",

      "GAU MINI 150K QT",
      "GAU MINI 150K NT",
      "GAU MINI 150K rebil",
      "GAU MINI 150K paid resets",
      "GAU MINI 150K free fresets",

      "GAU MINI 200K QT",
      "GAU MINI 200K NT",
      "GAU MINI 200K rebil",
      "GAU MINI 200K paid resets",
      "GAU MINI 200K free fresets",

      "RESETS QT",
      "RESETS NT",
      "FREE RESETS",
    ],
  ];

  report.forEach((row) => {
    const {
      date,
      gau50_net_not_us,
      gau50_rebill_net_not_us,
      gau100_net_not_us,
      gau100_rebill_net_not_us,
      gau150_net_not_us,
      gau150_rebill_net_not_us,
      gau200_net_not_us,
      gau200_rebill_net_not_us,
      tcp25_net_not_us,
      tcp25_rebill_net_not_us,
      tcp50_net_not_us,
      tcp50_rebill_net_not_us,
      tcp100_net_not_us,
      tcp100_rebill_net_not_us,

      gau50_net_us,
      gau50_rebill_net_us,
      gau100_net_us,
      gau100_rebill_net_us,
      gau150_net_us,
      gau150_rebill_net_us,
      gau200_net_us,
      gau200_rebill_net_us,
      tcp25_net_us,
      tcp25_rebill_net_us,
      tcp50_net_us,
      tcp50_rebill_net_us,
      tcp100_net_us,
      tcp100_rebill_net_us,

      resets_net_us,
      resets_net_not_us,

      gau50_qt,
      gau100_qt,
      gau150_qt,
      gau200_qt,
      tcp25_qt,
      tcp50_qt,
      tcp100_qt,

      gau50_paid_reset_qt,
      gau50_free_reset_qt,
      gau100_paid_reset_qt,
      gau100_free_reset_qt,
      gau150_paid_reset_qt,
      gau150_free_reset_qt,
      gau200_paid_reset_qt,
      gau200_free_reset_qt,
      tcp25_paid_reset_qt,
      tcp25_free_reset_qt,
      tcp50_paid_reset_qt,
      tcp50_free_reset_qt,
      tcp100_paid_reset_qt,
      tcp100_free_reset_qt,

      gau50_rebill_qt,
      gau100_rebill_qt,
      gau150_rebill_qt,
      gau200_rebill_qt,
      tcp25_rebill_qt,
      tcp50_rebill_qt,
      tcp100_rebill_qt,

      count_free_resets_total,
      count_reset_total,
    } = row;

    const us_net =
      gau50_net_us +
      gau100_net_us +
      gau150_net_us +
      gau200_net_us +
      tcp25_net_us +
      tcp50_net_us +
      tcp100_net_us +
      gau50_rebill_net_us +
      gau100_rebill_net_us +
      gau150_rebill_net_us +
      gau200_rebill_net_us +
      tcp25_rebill_net_us +
      tcp50_rebill_net_us +
      tcp100_rebill_net_us;

    const not_us_net =
      gau50_net_not_us +
      gau100_net_not_us +
      gau150_net_not_us +
      gau200_net_not_us +
      tcp25_net_not_us +
      tcp50_net_not_us +
      tcp100_net_not_us +
      gau50_rebill_net_not_us +
      gau100_rebill_net_not_us +
      gau150_rebill_net_not_us +
      gau200_rebill_net_not_us +
      tcp25_rebill_net_not_us +
      tcp50_rebill_net_not_us +
      tcp100_rebill_net_not_us;

    const total_net = us_net + not_us_net + resets_net_us + resets_net_not_us;
    const reportData = [
      date,
      total_net.toString(),
      (us_net + resets_net_us).toString(),
      (not_us_net + resets_net_not_us).toString(),

      tcp25_qt.toString(),
      (tcp25_net_not_us + tcp25_net_us + tcp25_rebill_net_not_us + tcp25_rebill_net_us).toString(),
      tcp25_rebill_qt.toString(),
      tcp25_paid_reset_qt.toString(),
      tcp25_free_reset_qt.toString(),

      tcp50_qt.toString(),
      (tcp50_net_not_us + tcp50_net_us + tcp50_rebill_net_not_us + tcp50_rebill_net_us).toString(),
      tcp50_rebill_qt.toString(),
      tcp50_paid_reset_qt.toString(),
      tcp50_free_reset_qt.toString(),

      tcp100_qt.toString(),
      (
        tcp100_net_not_us +
        tcp100_net_us +
        tcp100_rebill_net_not_us +
        tcp100_rebill_net_us
      ).toString(),
      tcp100_rebill_qt.toString(),
      tcp100_paid_reset_qt.toString(),
      tcp100_free_reset_qt.toString(),

      gau50_qt.toString(),
      (gau50_net_not_us + gau50_net_us + gau50_rebill_net_not_us + gau50_rebill_net_us).toString(),
      gau50_rebill_qt.toString(),
      gau50_paid_reset_qt.toString(),
      gau50_free_reset_qt.toString(),

      gau100_qt.toString(),
      (
        gau100_net_not_us +
        gau100_net_us +
        gau100_rebill_net_not_us +
        gau100_rebill_net_us
      ).toString(),
      gau100_rebill_qt.toString(),
      gau100_paid_reset_qt.toString(),
      gau100_free_reset_qt.toString(),

      gau150_qt.toString(),
      (
        gau150_net_not_us +
        gau150_net_us +
        gau150_rebill_net_not_us +
        gau150_rebill_net_us
      ).toString(),
      gau150_rebill_qt.toString(),
      gau150_paid_reset_qt.toString(),
      gau150_free_reset_qt.toString(),

      gau200_qt.toString(),
      (
        gau200_net_not_us +
        gau200_net_us +
        gau200_rebill_net_not_us +
        gau200_rebill_net_us
      ).toString(),
      gau200_rebill_qt.toString(),
      gau200_paid_reset_qt.toString(),
      gau200_free_reset_qt.toString(),

      count_reset_total.toString(),
      (resets_net_us + resets_net_not_us).toString(),
      count_free_resets_total.toString(),
    ];

    if (!isIgnoreWeekends) {
      data.push(reportData);
    }

    if (isIgnoreWeekends && !isWeekend(new Date(date))) {
      data.push(reportData);
    }
  });

  const getFileName = (): string => {
    if (period.isAllTime) return "sales_by_days-all-time";
    return `sales_by_days-${period.startDate}-per-${period.endDate}`;
  };

  downloadCsv(data, getFileName());
};
