import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik } from "formik";
import { Button, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { FUNDS_API_URLS, FundsAPI } from "../../../../api/funds";
import { IFundedTrader } from "../../../../api/funds/index.types";
import Input from "../../../../components/ui/FormInput";
import useStyles from "./useStyles";
import { PRE_ENVOY_DETAILS_SCHEMA } from "./constants";
import { CENTS_IN_DOLLAR } from "../../../../constants";
import {
  NUMBERS_REGEX,
  FLOAT_NUMBERS_REGEX,
  NUMBER_DISABLED_CHARECTERS,
} from "../../../../utils/validation";

type TFundedAccountDocumentDates = Pick<
  IFundedTrader,
  "passes_before_envoy" | "withdrawals_before_envoy" | "total_withdrawn_before_envoy"
>;
const PreEnvoyData = (): React.ReactElement | null => {
  const classes = useStyles();
  const params: { id: string } = useParams();
  const id = +params.id;
  const queryClient = useQueryClient();
  const {
    isLoading,
    withdrawals_before_envoy,
    passes_before_envoy,
    total_withdrawn_before_envoy,
    accounts_id,
  } = useSelector((state) => state.fundedTrader);

  const getInitialValues = () => ({
    passes_before_envoy,
    withdrawals_before_envoy: withdrawals_before_envoy / CENTS_IN_DOLLAR,
    total_withdrawn_before_envoy: total_withdrawn_before_envoy / CENTS_IN_DOLLAR,
  });

  const [initialValues, setInitialValues] = useState<TFundedAccountDocumentDates>(
    getInitialValues()
  );
  const handleUpdateFundedTraderPreEnvoyStatus = useMutation({
    mutationKey: [FUNDS_API_URLS.update_trader, id],
    mutationFn: (body: Partial<TFundedAccountDocumentDates>) =>
      FundsAPI.updateFundedTraderDataRequest(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.get_funded_trader_data, id] });
    },
  });

  const handleSubmit = async (data: TFundedAccountDocumentDates) => {
    const changedValues: Partial<TFundedAccountDocumentDates> = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value !== initialValues[key as keyof TFundedAccountDocumentDates]) {
        if (key === "passes_before_envoy") {
          changedValues[key as keyof TFundedAccountDocumentDates] = value;
        } else {
          changedValues[key as keyof TFundedAccountDocumentDates] = value * CENTS_IN_DOLLAR;
        }
      }
    });
    handleUpdateFundedTraderPreEnvoyStatus.mutate({
      ...changedValues,
    });
  };

  useEffect(() => {
    setInitialValues(getInitialValues());
  }, [passes_before_envoy, withdrawals_before_envoy, total_withdrawn_before_envoy, accounts_id]);

  if (isLoading) {
    return null;
  }

  const checkIsValidValue = (value: string): boolean =>
    (!NUMBER_DISABLED_CHARECTERS.includes(value) && NUMBERS_REGEX.test(value)) || !value;

  return (
    <div>
      <Accordion classes={{ root: classes.rootAccordion }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <b className={classes.title}>Pre-Envoy details</b>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.rootAccordionDetails }}>
          <Formik
            initialValues={initialValues}
            validateOnChange
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={PRE_ENVOY_DETAILS_SCHEMA}
          >
            {({ isValid, dirty, setFieldValue }) => {
              return (
                <Form className={classes.form}>
                  <div>
                    <Field
                      name="passes_before_envoy"
                      label="Past passes"
                      placeholder="Past passes"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const { name, value } = e.target;
                        if (checkIsValidValue(value)) {
                          setFieldValue(name, value);
                        }
                      }}
                      component={Input}
                    />
                  </div>

                  <div>
                    <Field
                      name="withdrawals_before_envoy"
                      label="Total Withdrawal Requests"
                      placeholder="Withdrawals"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const { name, value } = e.target;
                        if (checkIsValidValue(value)) {
                          setFieldValue(name, value);
                        }
                      }}
                      component={Input}
                    />
                  </div>

                  <div>
                    <Field
                      name="total_withdrawn_before_envoy"
                      label="Total Withdrawn"
                      placeholder="Total Withdrawn"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const { name, value } = e.target;
                        if (FLOAT_NUMBERS_REGEX.test(value) || !value) {
                          setFieldValue(name, value);
                        }
                      }}
                      component={Input}
                    />
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!(isValid && dirty)}
                    type="submit"
                  >
                    Save
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PreEnvoyData;
