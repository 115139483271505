import React from "react";
import { Grid } from "@material-ui/core/";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";

import { APIAccounts } from "../../../../../../api";
import { ACCOUNT_STATUS_OPTIONS } from "../../utils";
import { FormSelect, ConfirmDialog } from "../../../../../../components/ui";
import { ACCOUNTS_API_URLS } from "../../../../../../api/accounts";

const AccountStatus = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const queryClient = useQueryClient();
  const {
    loading,
    data: { status },
  } = useSelector((state) => state.customer.managementData);

  const { banned_account } = useSelector((state) => state.customer.mainData.data);
  const isDisabledByBunReason = "status" in (banned_account?.field_values_before_bun || {});

  const handleChangeAccountStatus = async ({ status }: { status: number }) => {
    try {
      await APIAccounts.updateAccountDataRequest(id, { status });
      await queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.getAccountManagementData, id],
      });
    } catch (error) {
      console.error("Error change account status", error);
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Formik
            enableReinitialize
            initialValues={{ status }}
            onSubmit={handleChangeAccountStatus}
          >
            {({ dirty, handleReset, handleSubmit }) => (
              <Form>
                <ConfirmDialog
                  isOpen={dirty}
                  dialogTitle="Change account status"
                  dialogContentText="Are you sure you want to change account status?"
                  handleClickOk={() => handleSubmit()}
                  handleCancel={() => handleReset()}
                />

                <Field
                  name="status"
                  label="Status"
                  placeholder="Status"
                  component={FormSelect}
                  options={ACCOUNT_STATUS_OPTIONS}
                  disabled={loading || isDisabledByBunReason}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountStatus;
