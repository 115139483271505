import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";

import { APILogins } from "../../../../api";
import { ILogins } from "../../../../api/logins/index.types";
import useStyles from "../../useStyles";
import { useAuthContext } from "../../../../context";
import { DATE_FORMAT_hhmmssAzz } from "../../../../constants";

const Last5Logins = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const classes = useStyles();
  const [logins, setLogins] = useState<ILogins[]>([]);
  const { hasExpired, loginAndQuery } = useAuthContext();

  const getLogins = async () => {
    const data = { limit: 5 };
    try {
      const response = await APILogins.getLastLoginsRequest(id.toString(), data);
      setLogins(response.data.logins);
    } catch (error) {
      console.error(error);
      hasExpired();
    }
  };
  useEffect(() => {
    loginAndQuery(getLogins);
  }, [id]);

  return (
    <div>
      <b>Last 5 Logins: </b> (America/Chicago)
      <div className={classes.tableBorder}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>IP</th>
              <th>Success</th>
            </tr>
          </thead>
          <tbody>
            {logins.map((plan) => (
              <tr key={`${plan.timestamp}_${plan.ip}`}>
                <td data-test="timezone">
                  {moment(plan.timestamp).tz("America/Chicago").format(DATE_FORMAT_hhmmssAzz)}
                </td>
                <td>{plan.ip}</td>
                <td style={{ textAlign: "center" }}>
                  {plan.success ? (
                    <b style={{ color: "green" }}>✔</b>
                  ) : (
                    <b style={{ color: "red" }}>✘</b>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Last5Logins;
