import React, { useState, useEffect } from "react";
import { uniqueId } from "lodash";
import { useSelector } from "react-redux";

import Table from "../../../../../../ComplianceSearch/components/Table";
import { IPlan } from "../../../../../../../api/plans/index.types";
import { isWeekend } from "../../../../../../../utils";
import { TABLE_PLANS } from "../../constants";
import { PRODUCT_SALES_COLUMNS } from "./constants";

export interface IReport {
  plan: string;
  total_income: number;
  total_case: number;
  total_amount: number;
}
interface IProps {
  isIgnoreWeekends: boolean;
}
const ProductStatsTable: React.FC<IProps> = ({ isIgnoreWeekends }) => {
  const { plans, financials } = useSelector((state) => state);
  const { data: salesReport } = financials.sales_by_days;

  const [tablePlansData, setTablePlansData] = useState<IPlan[]>([]);
  const [report, setReport] = useState<IReport[]>([]);

  useEffect(() => {
    const plansToShowData = TABLE_PLANS.map((p) => plans.data.plans.find((plan) => plan.key === p));
    setTablePlansData(plansToShowData as IPlan[]);
  }, [plans]);

  useEffect(() => {
    if (!!salesReport.length && !!plans.data.plans.length) {
      const report = tablePlansData.map(({ key, name }) => {
        const values = {
          total_income: 0,
          total_case: 0,
          total_amount: 0,
        };

        salesReport.forEach((data) => {
          const netNotUs = data[`${key.toLowerCase()}_net_not_us` as keyof typeof data] as number;
          const netUs = data[`${key.toLowerCase()}_net_us` as keyof typeof data] as number;
          const qt = data[`${key.toLowerCase()}_qt` as keyof typeof data] as number;
          // const rebillQt = data[`${key.toLowerCase()}_rebill_qt` as keyof typeof data] as number;

          if (!isIgnoreWeekends) {
            values.total_income += netNotUs + netUs;
            values.total_case += qt; // + rebillQt;
          }

          if (isIgnoreWeekends && !isWeekend(new Date(data.date))) {
            values.total_income += netNotUs + netUs;
            values.total_case += qt; // + rebillQt;
          }
        });

        return {
          plan: name,
          ...values,
        };
      });
      setReport(report);
    }
  }, [salesReport, tablePlansData, isIgnoreWeekends]);

  return (
    <div>
      <Table
        columns={PRODUCT_SALES_COLUMNS}
        loading={plans.loading}
        extractKey={(row) => `${row.plan}_${uniqueId()}`}
        data={report}
        csv_table_name={"Product sales"}
      />
    </div>
  );
};

export default ProductStatsTable;
