export const TABS_PROPS = {
  salesStats: {
    label: "Sales Stats",
    value: 0,
  },
  countryStats: {
    label: "Country Stats",
    value: 1,
  },
  traderStats: {
    label: "Trader Stats",
    value: 2,
  },
  salesAnalitics: {
    label: "Sales Analitics",
    value: 3,
  },
  disputes: {
    label: "Disputes",
    value: 4,
  },
};

export const PLANS_NAMES_MAP = {
  GAU50: "Gauntlet Mini 50k",
  GAU100: "Gauntlet Mini 100k",
  GAU150: "Gauntlet Mini 150k",
  GAU200: "Gauntlet Mini 200k",

  TCP25: "Trader Career Path 25k",
  TCP50: "Trader Career Path 50k",
  TCP100: "Trader Career Path 100k",
  TCP150: "Trader Career Path 150k",
  TCP200: "Trader Career Path 200k",
};
