import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import useStyles from "../useStyles";
import SelectByLiveSimOrLive from "../../../../../components/ui/SelectByLiveSimOrLive";
import SelectByTimeFrame from "../../../../../components/ui/SelectByTimeFrame";
import { TTimeFrame } from "../../../../../components/ui/SelectByTimeFrame";
import { TTypeAccount } from "../../../../../components/ui/SelectByLiveSimOrLive";
import SelectByDateWithCustomPeriod from "../../../../../components/ui/SelectByDateWithCustomPeriod";
import { DATES_VALUE } from "../../../../../constants";
import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import { BillingAPI } from "../../../../../api/billing";
import Select from "../../../../../components/ui/Select";
import { IntlFormatUSD } from "../../../../../utils";

const PerSales: React.FC = () => {
  const classes = useStyles();
  const { data, loading } = useSelector((state) => state.financials.withdrawal_per_sales);
  const { billingHistory, w80 } = data;

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>("week");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");
  const [slippagePeriod, setSlippagePeriod] = useState<number>(1);

  const [columns, setColumns] = useState<IColum[]>([]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_sales",
      startDate,
      endDate,
      isAllTime,
      timeFrame,
      typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal per sales");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerSalesFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(data.w80.map(({ period }) => period)));

    const columns = buildColumns({
      dates,
      reportType: "amount",
      isUseChangeToPrev: false,
      initialColumns: [
        {
          by: "plan",
          title: "Plan",
          path: "plan",
        },
        {
          by: "slippage_period",
          title: "Slippage",
          path: "plan",
          // @ts-ignore
          getCellValue: (value: number[], restValues: IReport) =>
            IntlFormatUSD(restValues.values.reduce((acc, curr) => acc + curr, 0) / slippagePeriod),
          // @ts-ignore
          copyToClipBoardCallBack: (value, restValues: IReport) =>
            IntlFormatUSD(restValues.values.reduce((acc, curr) => acc + curr, 0) / slippagePeriod),
        },
      ],
    });
    setColumns(columns);

    const reportWithWithdawalPerSales = w80.map((report) => {
      const billingHistoryByPlan = billingHistory.find(({ period, plan }) => {
        return period === report.period && report.account_type === plan?.key;
      });

      return {
        ...report,
        account_size: "",
        total_amount: billingHistoryByPlan
          ? report.total_amount / billingHistoryByPlan.total_count
          : report.total_amount,
      };
    });

    const report = buildReport({
      data: reportWithWithdawalPerSales,
      dates,
      options: { isShowTotal: false },
    });

    setReport(report);
  }, [data, slippagePeriod]);

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };

  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  const handleChangeSlippagePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSlippagePeriod(event.target.value as number);
  };

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Withdrawal per Sales</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame
            value={timeFrame}
            onChange={handleChangeTimeFrame}
            excludeOptions={["day"]}
          />
        </div>
        <div className={classes.timeFrameContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
        <div className={classes.timeFrameContainer}>
          <Select
            label="Slippage period"
            inputLabel="Slippage period"
            value={slippagePeriod}
            onChange={handleChangeSlippagePeriod}
            options={[
              { text: "1", name: 1 },
              { text: "2", name: 2 },
              { text: "3", name: 3 },
              { text: "4", name: 4 },
              { text: "5", name: 5 },
            ]}
          />
        </div>
      </div>

      <Table
        csv_table_name="Withdrawal per Sales"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default PerSales;
