import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  timeFrameContainer: {
    marginLeft: 10,
    width: "200px",
  },
  typeContainer: {
    marginLeft: 10,
  },
});

export default useStyles;
