import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { useQuery } from "@tanstack/react-query";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import { PLANS_NAMES_MAP } from "../../../constants";
import useStyles from "../useStyles";
import { BILLING_API_URLS } from "../../../../../api/billing";
import SelectByTimeFrame, { TTimeFrame } from "../../../../../components/ui/SelectByTimeFrame";
import SelectByLiveSimOrLive, {
  TTypeAccount,
} from "../../../../../components/ui/SelectByLiveSimOrLive";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import { BillingAPI } from "../../../../../api/billing";
import SelectByDateWithCustomPeriod from "../../../../../components/ui/SelectByDateWithCustomPeriod";
import { DATES_VALUE } from "../../../../../constants";

const PerTifRegistration: React.FC = () => {
  const classes = useStyles();
  const { data, loading } = useSelector(
    (state) => state.financials.withdrawal_per_tif_registration
  );
  const { tifRegistration, w80 } = data;

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>("week");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");

  const [columns, setColumns] = useState<IColum[]>([
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_tif_registration",
      startDate,
      endDate,
      isAllTime,
      timeFrame,
      typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal per TIF registration");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerTIFRegistrationFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(tifRegistration.map(({ period }) => period)));
    setColumns(buildColumns({ dates, reportType: "amount", isUseChangeToPrev: false }));

    const reportWithWithdawalPerRequest = w80.map((report) => {
      const tifRegistrationData = tifRegistration.find(({ period, evaluation_completed }) => {
        return (
          period === report.period &&
          PLANS_NAMES_MAP[report.account_type as keyof typeof PLANS_NAMES_MAP] ===
            evaluation_completed
        );
      });
      return {
        ...report,
        account_size: "",
        total_amount: tifRegistrationData
          ? report.total_amount / tifRegistrationData.total_count
          : report.total_amount,
      };
    });

    setReport(
      buildReport({ data: reportWithWithdawalPerRequest, dates, options: { isShowTotal: false } })
    );
  }, [data]);

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };

  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Withdrawal per TIF registration</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame
            value={timeFrame}
            onChange={handleChangeTimeFrame}
            excludeOptions={["day"]}
          />
        </div>
        <div className={classes.timeFrameContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
      </div>

      <Table
        csv_table_name="Withdrawal per TIF registration"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default PerTifRegistration;
