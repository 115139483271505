import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { APIGoogleDrive } from "../../../../api";
import { G_DRIVE_API_URLS } from "../../../../api/googleDrive";
import { UploadedFilesList } from "../index";
import { FundedTraderFilesActions } from "../../../../reducers/fundedTraderFilesReducer/actions";
import DropZone from "../DropZone";

const UploadFilesGoogleDrive = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const dispatch = useDispatch();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  useQuery({
    queryKey: [G_DRIVE_API_URLS.getCustomerFiles, id],
    queryFn: async () => {
      dispatch(FundedTraderFilesActions.fetchFundedTraderFilesRequest());
      const response = await APIGoogleDrive.getCustomerFiles(id);
      dispatch(FundedTraderFilesActions.fetchFundedTraderFilesSuccess(response.data));
      return response;
    },
    onError: (error) => {
      dispatch(FundedTraderFilesActions.fetchFundedTraderFilesFailed());
      console.error("error", error);
    },
    enabled: isOpenDialog,
  });

  const handleOpenDialog = () => setIsOpenDialog(true);
  const handleCloseDialog = () => setIsOpenDialog(false);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Upload Files
      </Button>

      <Dialog fullWidth open={isOpenDialog} onClose={handleCloseDialog}>
        <DialogTitle>Upload files</DialogTitle>
        <DialogContent>
          <DropZone />
          <UploadedFilesList />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadFilesGoogleDrive;
