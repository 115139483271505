import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  Grid,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { APIURMA } from "../../../api";
import { ICreateUrmaAccountData } from "../../../api/urma/index.types";
import { useURMAContext } from "../../../context";
import { TTypeMessage } from "../../ui/Snackbar";
import {
  URMA_ACCOUNT_SCHEMA,
  ACCOUNT_CLASS_OPTIONS,
  URMA_ACCOUNT_INITIAL_STATE_FORM,
} from "../constants";
import { FormInput, FormSelect, FormChipInput, FormCheckbox, Snackbar } from "../../ui";

const StyledFrom = styled(Form)({ marginTop: 10 });

interface IProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}
const CreateURMAAccountModal = ({ open, setOpen }: IProps): React.ReactElement => {
  const { setUrmaAccount, urmaUser } = useURMAContext();
  const [isFetching, setFetching] = useState(false);

  const [snackBarState, setSnackBarState] = useState({
    open: false,
    resultType: "" as TTypeMessage,
    responseSuccessMessage: "Created",
    responseSuccessErrorMessage: "",
  });
  const handleCloseSnackBar = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const handleClose = () => setOpen(false);
  const handleSubmit = async (values: ICreateUrmaAccountData) => {
    try {
      setFetching(true);
      const response = await APIURMA.createUrmaAccountRequest(values);
      setUrmaAccount(response.data);
      setSnackBarState({
        ...snackBarState,
        resultType: "success",
        open: true,
      });
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (error) {
      const e = error as { response: { data: { message: string } } };
      setSnackBarState({
        ...snackBarState,
        resultType: "error",
        responseSuccessErrorMessage: e.response.data.message,
        open: true,
      });
    } finally {
      setFetching(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-create-account">
      <DialogTitle>Create account</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            ...URMA_ACCOUNT_INITIAL_STATE_FORM,
            profile_id: urmaUser.profile_id,
          }}
          onSubmit={handleSubmit}
          validationSchema={URMA_ACCOUNT_SCHEMA}
        >
          {({ isValid }) => (
            <StyledFrom>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field name="name" label="Name" placeholder="Name" component={FormInput} />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="balance"
                    label="Balance"
                    placeholder="Balance"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="account_class"
                    label="Account class"
                    placeholder="Account class"
                    component={FormSelect}
                    options={ACCOUNT_CLASS_OPTIONS}
                    contextualHelpText="Class type of an account:"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Field
                    name="brokerage_account_number"
                    label="Brokerage account number"
                    placeholder="Brokerage account number"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="profile_id"
                    label="Profile id"
                    placeholder="Profile id"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="account_type_id"
                    label="Account type id"
                    placeholder="Account type id"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="sales_series_id"
                    label="Sales series id"
                    placeholder="Sales series id"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="auth_traders"
                    label="Auth traders"
                    placeholder="Auth traders"
                    component={FormChipInput}
                    contextualHelpText="User trader ids"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="trading_routes"
                    label="Trading routes"
                    placeholder="Trading routes"
                    component={FormChipInput}
                    contextualHelpText="Trading routes ids"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="is_active"
                    label="Active"
                    placeholder="Active"
                    component={FormCheckbox}
                  />
                </Grid>
              </Grid>

              <div>
                <DialogActions>
                  <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid || isFetching}
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    Create
                  </Button>
                </DialogActions>
              </div>
            </StyledFrom>
          )}
        </Formik>
      </DialogContent>

      <Snackbar
        type={snackBarState.resultType}
        isOpenSnackbar={snackBarState.open}
        responseSuccessMessage={snackBarState.responseSuccessMessage}
        responseErrorMessage={snackBarState.responseSuccessErrorMessage}
        onClose={handleCloseSnackBar}
      />
    </Dialog>
  );
};

export default CreateURMAAccountModal;
