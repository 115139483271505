import React from "react";
import Select from "../Select";

const OPTIONS = [
  { text: "All", name: "all" },
  { text: "LiveSim", name: "LiveSim" },
  { text: "Live", name: "Live" },
];
export type TTypeAccount = (typeof OPTIONS)[number]["name"];

interface IProps {
  value: TTypeAccount;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}
const SelectByLiveSimOrLive: React.FC<IProps> = ({ value, onChange }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={OPTIONS}
      label="Account type"
      inputLabel="Account type"
      fullWidth
    />
  );
};

export default SelectByLiveSimOrLive;
