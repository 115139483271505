import React from "react";
import { Grid } from "@material-ui/core";

import { APIStripe } from "../../../../api";
import { StatusList } from "../../../Customer/Customer";
import ReportChartWithDateSelect from "../ReportChartsWithDateSelect/ReportChartWithDateSelect";

const TabDisputes: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <h2>Refunds</h2>
        <div>
          <ReportChartWithDateSelect
            getReportRequest={APIStripe.getAllRefundsRequest}
            type="refunded"
            purchaseStatus={StatusList.refunded}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <h2>Chargebacks</h2>
        <div>
          <ReportChartWithDateSelect
            getReportRequest={APIStripe.getAllChargebacks}
            type="chargeback"
            purchaseStatus={StatusList.chargeback}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TabDisputes;
