import React from "react";
import WithdrawalStats from "../WithdrawalStats";
import TIFStats from "../TIFStats";
import WithdrawalRates from "../WithdrawalRates";

const TabTraderStats: React.FC = () => {
  return (
    <div>
      <WithdrawalStats />
      <TIFStats />
      <WithdrawalRates />
    </div>
  );
};

export default TabTraderStats;
