import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import LogsHistoryTable from "../../../../components/ui/LogsHistoryTable";
import { AgentsLogActivityActions } from "../../../../reducers/agentsLogsActivityReducer";
import { APIAgentActivityLog } from "../../../../api";
import { useAgentsLogsHistoryContext } from "../../../../context";
import { ISortBy } from "../../../../types";

const AgentLogsHistoryTable = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "log_time",
    order: false,
  });
  const {
    loading,
    data: { count, logs },
  } = useSelector((state) => state.agentsLogsActivityReducer);
  const { limit, page, setPage, setLimit, search, setSearch } = useAgentsLogsHistoryContext();

  useEffect(() => {
    (async () => {
      try {
        dispatch(AgentsLogActivityActions.fetchAgentsLogsRequest());
        const response = await APIAgentActivityLog.getActivityLogsRequest({
          limit,
          page,
          sortBy,
          contains: search,
        });
        dispatch(AgentsLogActivityActions.fetchAgentsLogsSuccess(response));
      } catch (error) {
        console.error("Error fetching agents activity logs", error);
        dispatch(AgentsLogActivityActions.fetchAgentsLogsFailed());
      }
    })();
  }, [limit, page, sortBy, search, id]);
  return (
    <div>
      <LogsHistoryTable
        logs={logs}
        loading={loading}
        count={count}
        pagination={{ limit, setLimit, count, page, setPage }}
        handleSort={[sortBy, setSortBy]}
        handleSearch={[search, setSearch]}
      />
    </div>
  );
};

export default AgentLogsHistoryTable;
