import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as LogRocket from "logrocket";

import useStyles from "./useStyles";
import TableBody from "../TableBody";
import TableHeader from "../TableHeader";
import {
  useAdminProviderDataContext,
  useAuthContext,
  useDiscountsContext,
} from "../../../../context";
import { DiscountCodesActions } from "../../../../reducers/discountCodesReducer";
import { APIDiscounts } from "../../../../api";
import { DiscountCodeState } from "../../../../api/discounts/index.types";
import {
  CREATED_DISCOUNT_CODE_INITIAL_STATE,
  DISCOUNT_CODES_FILTERS,
  INPUT_TYPES,
} from "../../constants";
import { setPage } from "../../../../utils";
import {
  ContainerWithLoader,
  FilterAutocomplete,
  DiscountsModal,
  TableColumnsView,
  PaginationWithLimit,
  FilterAutocompleteRef,
} from "../../../../components/ui";
import { isDevelopEnv } from "../../../../constants";

const DiscountCodes = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loginAndQuery, hasExpired } = useAuthContext();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const discountsCount = useSelector((state) => state.discountCodes.data.count);
  const { discount_codes, count } = useSelector((state) => state.discountCodes.data);

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDiscountModal, setIsOpenDiscountModal] = useState(false);

  const [isEditingDiscountCode, setIsEditingDiscountCode] = useState(false);
  const [selectedDiscountCode, setSelectedDiscountCode] = useState<DiscountCodeState>(
    CREATED_DISCOUNT_CODE_INITIAL_STATE
  );

  const {
    COLUMNS,
    columns,
    setColumns,
    sortBy,
    inactive,
    search,
    setSearch,
    page,
    _setPage,
    limit,
    _setLimit,
  } = useDiscountsContext();

  const getDiscount = async () => {
    const data = {
      limit,
      page,
      contains: search,
      sortBy,
      inactive: true,
    };

    dispatch(DiscountCodesActions.fetchRequest());
    try {
      setIsLoading(true);
      const response = await APIDiscounts.getDiscountCodesRequest(data);
      const responseData = response.data;
      dispatch(DiscountCodesActions.fetchSuccess(responseData));
    } catch (error) {
      const e = error as { response: { data: string } };
      hasExpired();
      e.response.data === "Expired" ? console.error("Session Expired!") : console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetPage = (v: number) => setPage(v, discountsCount, +limit, _setPage);
  const setLimit = (value: string) => {
    try {
      _setLimit(value);
    } catch (err) {
      console.error("Invalid number");
    }
  };

  const handleShowExternalColumns = (value: string[]) => setColumns(value);
  const handleClickCreatedBy = (adminName: string | null) => {
    if (adminName) {
      setSearch({
        ...search,
        createdBy: adminName,
      });
      setKey(key + 1);
    }
  };
  const [key, setKey] = useState(1);

  const handleClickDiscountCodeAction = (discountCode: DiscountCodeState, isEditing: boolean) => {
    setSelectedDiscountCode(discountCode);
    setIsEditingDiscountCode(isEditing);
    setTimeout(() => {
      setIsOpenDiscountModal(true);
    }, 0);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      loginAndQuery(getDiscount);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [page, sortBy, inactive, search, limit]);

  useEffect(() => {
    LogRocket.track("Discounts: View All");
  }, []);

  useEffect(() => {
    if (!isOpenDiscountModal) {
      setSelectedDiscountCode(CREATED_DISCOUNT_CODE_INITIAL_STATE);
      setIsEditingDiscountCode(false);
    }
  }, [isOpenDiscountModal]);

  const [searchDev, setSearchDev] = useState({
    // quantity: 0,
  });
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <DiscountsModal
        isOpen={isOpenDiscountModal}
        setIsOpen={setIsOpenDiscountModal}
        getDiscount={getDiscount}
        isEditingDiscountCode={isEditingDiscountCode}
        selectedDiscountCode={selectedDiscountCode}
      />

      <div className="discounts">
        <div className={classes.searchContainer}>
          <TableColumnsView
            columns={COLUMNS}
            relatedTables={columns}
            handleShowExternalColumns={handleShowExternalColumns}
          />
          {allowed_features.access_to_create_discount_code && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsOpenDiscountModal(true)}
            >
              Create discount code
            </Button>
          )}
        </div>

        <div className={classes.filtersWrapper}>
          <FilterAutocomplete
            search={search}
            setSearch={setSearch}
            filters={DISCOUNT_CODES_FILTERS}
            inputTypes={INPUT_TYPES}
            clearValuesFilter={key}
            defaultSearchFields={{
              Code: "code",
              User: "user",
              Description: "description",
            }}
          />
        </div>

        {isDevelopEnv && (
          <div className={classes.filtersWrapper}>
            {/* TODO */}
            <FilterAutocompleteRef
              defaultProps={{
                multiple: true,
              }}
              search={searchDev}
              setSearch={setSearchDev}
              options={[
                { name: "Code", key: "code", default: true },
                { name: "Description", key: "description" },
                { name: "User", key: "user" },
                {
                  name: "Quantity",
                  key: "quantity",
                  subOptions: [
                    {
                      name: "more than",
                      key: ">",
                      type: "number",
                    },
                    {
                      name: "less than",
                      key: "<",
                      type: "number",
                    },
                    {
                      name: "equal to",
                      key: "=",
                      type: "number",
                    },
                  ],
                },
              ]}
            />
          </div>
        )}

        <p>
          Discount codes found:<b>{count}</b>
        </p>

        <div style={{ overflowX: "scroll" }}>
          <ContainerWithLoader report={discount_codes} isLoading={isLoading}>
            <table style={{ width: "100%" }}>
              <TableHeader />
              <TableBody
                handleClickDiscountCodeAction={handleClickDiscountCodeAction}
                getDiscount={getDiscount}
                handleClickCreatedBy={handleClickCreatedBy}
              />
            </table>
          </ContainerWithLoader>
        </div>

        <PaginationWithLimit
          count={discountsCount}
          limit={limit}
          setPageState={handleSetPage}
          page={page}
          setLimit={setLimit}
        />
      </div>
    </>
  );
};

export default DiscountCodes;
