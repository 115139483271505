import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import { APIAgentActivityLog } from "../../../../api";
import usePagination from "../../../../hooks/usePagination";
import { ISortBy } from "../../../../types";
import LogsHistoryTable from "../../../../components/ui/LogsHistoryTable";
import { AGENT_LOGS_ACTIVITY_FILTERS } from "../../../Discounts/constants";
import { AGENT_ACTIVITY_LOG_API_URLS } from "../../../../api/agentActivityLog";

const { Agent, Code } = AGENT_LOGS_ACTIVITY_FILTERS;
const CustomerHistoryLogs = (): React.ReactElement => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const id = +params.id;
  const pagination = usePagination();

  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "log_time",
    order: false,
  });
  const [search, setSearch] = useState({
    log_time: "",
  });

  const { loading, data } = useSelector((state) => state.customer.agentLogs);
  useQuery({
    queryKey: [
      AGENT_ACTIVITY_LOG_API_URLS.getCustomerLogs,
      id,
      pagination.limit,
      pagination.page,
      sortBy,
      search,
    ],
    queryFn: async () => {
      dispatch(CustomerActions.fetchAgentLogsRequest());
      const { data } = await APIAgentActivityLog.getCustomerLogsRequest({
        limit: pagination.limit,
        page: pagination.page,
        sortBy,
        contains: search,
        accounts_id: +id,
      });
      dispatch(CustomerActions.fetchAgentLogsSuccess(data.logs, data.count));
      pagination.setCount(data.count);
      return data;
    },
    onError: (error) => {
      console.error("Error fetching agent logs", error);
      dispatch(CustomerActions.fetchAgentLogsFailed());
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <h3>Customer logs</h3>
      <LogsHistoryTable
        count={data.count}
        logs={data.logs}
        loading={loading}
        pagination={pagination}
        handleSort={[sortBy, setSortBy]}
        handleSearch={[search, setSearch]}
        filters={{ Agent, Code }}
      />
    </div>
  );
};

export default CustomerHistoryLogs;
