import React from "react";
import { Grid } from "@material-ui/core";

import { APIBilling } from "../../../../api";
import ChargesOverTime from "../ChargesOverTime/ChargesOverTime";
import ReportChartWithDateSelect from "../ReportChartsWithDateSelect/ReportChartWithDateSelect";
import NetSalesCalculator from "../NetSalesCalculator";
import ProductsStatistic from "../ProductsStatistic";
import SalesSection from "../SalesSection";

const TabSalesStats: React.FC = () => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChargesOverTime />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h2>Sum of all payments</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIBilling.getReportRequest}
              type="sum"
              purchaseStatus="accepted"
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <h2>Plans purchased</h2>
          <div>
            <ReportChartWithDateSelect
              getReportRequest={APIBilling.getReportRequest}
              isShowList
              type="purchased"
              purchaseStatus="accepted"
            />
          </div>
        </Grid>
      </Grid>
      <NetSalesCalculator />
      <SalesSection />
      <ProductsStatistic />
    </div>
  );
};

export default TabSalesStats;
