import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { AFFILIATES_API_URLS, AffiliatesAPI } from "../../../../api/affiliates";

const Sales = (): React.ReactElement => {
  const { id }: { id: string } = useParams();

  useQuery({
    queryKey: [AFFILIATES_API_URLS.getAffiliateCodeUsage, id],
    async queryFn() {
      const params = new URLSearchParams();
      params.set("id", id);
      const response = await AffiliatesAPI.getAffiliateCodeUsageRequest(params.toString());
      console.log("response", response);
    },
    onError(error) {
      console.log("Error getting affiliate code usage", error);
    },
  });
  return <div>Sales: TODO</div>;
};

export default Sales;
