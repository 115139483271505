import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { Typography } from "@material-ui/core/";
import moment from "moment-timezone";

import { IntlFormatUSD } from "../../utils";
import { APIJourneys } from "../../api";
import { IUsedDiscountCodesWithJourneys, IJourney } from "../../api/journeys/index.types";
import useStyles from "./useStyles";
import useGeneralStyles from "../../useGeneralStyles";
import { ContainerWithLoader, MainInfoValue, StatusIcons } from "../../components/ui";
import { DATE_FORMAT } from "../../constants";
import { useAuthContext } from "../../context";

const Journey = (): React.ReactElement => {
  const { id }: { id: string } = useParams();
  const classes = useStyles();
  const generalClasses = useGeneralStyles();
  const { hasExpired } = useAuthContext();

  const [journeys, setJourneys] = useState<IUsedDiscountCodesWithJourneys[]>([]);
  const [journey, setJourney] = useState<IJourney>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await APIJourneys.getJourneyData(id);
        setJourneys(data.assignedDiscountCodes);
        setJourney(data.journey);
      } catch (error) {
        console.error("Error fetching Journey data", error);
        hasExpired();
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <div>
      <div className="fina fina-create">
        <div className="title">{journey?.name}</div>
        <div>
          <MainInfoValue title="Amount">
            <span>
              {journey?.amt}
              {journey?.type === "flat" ? "%" : "$"}
            </span>
          </MainInfoValue>
          <MainInfoValue title="Description">
            <span>{journey?.description}</span>
          </MainInfoValue>
          <MainInfoValue title="Quantity">
            <span>{journey?.qty}</span>
          </MainInfoValue>
          <MainInfoValue title="Code">
            <span>{journey?.code}</span>
          </MainInfoValue>
          <MainInfoValue title="Active">
            <span>
              <StatusIcons isActive={!!journey?.active} />
            </span>
          </MainInfoValue>
          <MainInfoValue title="Plan">
            <span>{journey?.plan.name}</span>
          </MainInfoValue>
          <MainInfoValue title="One time">
            <span>
              <StatusIcons isActive={!!journey?.one_time} />
            </span>
          </MainInfoValue>
          <MainInfoValue title="Expires">
            <span>
              {moment(journey?.expires).tz("America/Chicago").format(`${DATE_FORMAT} (H:mm)`)}
            </span>
          </MainInfoValue>
          <MainInfoValue title="Reset">
            <span>
              <StatusIcons isActive={!!journey?.reset} />
            </span>
          </MainInfoValue>
        </div>
        <div className={cx(generalClasses.scrollableContainer, classes.tableContainer)}>
          <Typography variant="h6">Journey info usage</Typography>
          <ContainerWithLoader isLoading={isLoading} report={journeys}>
            <table className={generalClasses.table}>
              <thead>
                <tr>
                  <td>Customer</td>
                  <td>Code</td>
                  <td>Use count</td>
                  <td>Money made</td>
                </tr>
              </thead>
              <tbody>
                {journeys.map(({ id, account, code, billing_histories }) => (
                  <tr key={id}>
                    <td>{account.name}</td>
                    <td>{code}</td>
                    <td>{billing_histories.length}</td>
                    <td>
                      {IntlFormatUSD(
                        billing_histories.reduce(
                          (acc, currentValue) => acc + currentValue.price_usd / 100,
                          0
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerWithLoader>
        </div>
      </div>
    </div>
  );
};

export default Journey;
