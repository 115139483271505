import React from "react";
import Select from "../Select";

const OPTIONS = [
  { text: "Daily", name: "day" },
  { text: "Weekly", name: "week" },
  { text: "Monthly", name: "month" },
  { text: "Quarterly", name: "quarter" },
];
export type TTimeFrame = (typeof OPTIONS)[number]["name"];

interface IProps {
  value: TTimeFrame;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  excludeOptions?: TTimeFrame[];
}
const SelectByTimeFrame: React.FC<IProps> = ({ value, onChange, excludeOptions }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={OPTIONS.filter((option) => !excludeOptions?.includes(option.name))}
      label="Time Frame"
      inputLabel="Time Frame"
    />
  );
};

export default SelectByTimeFrame;
