import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LogsHistoryTable from "../../../../components/ui/LogsHistoryTable";
import { APIAgentActivityLog } from "../../../../api";
import { IActivityLogsData } from "../../../../api/agentActivityLog/index.types";
import usePagination from "../../../../hooks/usePagination";
import { ISortBy } from "../../../../types";
import { AGENT_LOGS_ACTIVITY_FILTERS } from "../../../Discounts/constants";

const { Code, Customer } = AGENT_LOGS_ACTIVITY_FILTERS;

const AgentLogs = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const pagination = usePagination();

  const [sortBy, setSortBy] = useState<ISortBy>({
    by: "log_time",
    order: false,
  });
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [logs, setLogs] = useState<IActivityLogsData[]>([]);
  const [search, setSearch] = useState({
    log_time: "",
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await APIAgentActivityLog.getActivityLogsRequest({
          limit: pagination.limit,
          page: pagination.page,
          sortBy,
          contains: search,
          admin_accounts_id: id,
        });
        setCount(data.count);
        pagination.setCount(data.count);
        setLogs(data.logs);
      } catch (error) {
        console.error("Error fetching agent logs", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [pagination.limit, pagination.page, sortBy, id, search]);

  return (
    <div>
      <LogsHistoryTable
        logs={logs}
        loading={loading}
        count={count}
        handleSort={[sortBy, setSortBy]}
        pagination={pagination}
        handleSearch={[search, setSearch]}
        filters={{ Code, Customer }}
      />
    </div>
  );
};

export default AgentLogs;
