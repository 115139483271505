import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DiscountsModal } from "../../../../components/ui";
import { useCustomerProvider } from "../../../../context";
import DialogConfirm from "../DialogConfirm/DialogConfirm";

const CreateDiscount = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const { customer } = useCustomerProvider();
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOpenModal = () => setIsOpen(true);
  return (
    <>
      <DialogConfirm
        isShowMainButton
        dialogTitle="Create discount"
        contentText="Are you sure you want to create discount ?"
        buttonText="Create discount"
        onClickConfirm={handleClickOpenModal}
      />
      <DiscountsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        prefilledEmail={customer.email}
        id={Number(id)}
      />
    </>
  );
};

export default CreateDiscount;
