import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup, Radio, FormControl, FormControlLabel, FormLabel } from "@material-ui/core";

import SelectByLiveSimOrLive, {
  TTypeAccount,
} from "../../../../components/ui/SelectByLiveSimOrLive";
import useStyles from "./useStyles";
import { DATES_VALUE } from "../../../../constants";
import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import SelectByDateWithCustomPeriod from "../../../../components/ui/SelectByDateWithCustomPeriod";
import { BILLING_API_URLS, BillingAPI } from "../../../../api/billing";
import SelectByTimeFrame, { TTimeFrame } from "../../../../components/ui/SelectByTimeFrame";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import Table from "../../../ComplianceSearch/components/Table";
import { IReport, buildColumns, buildReport } from "../utils";

const WithdrawalStats: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { withdrawal_stats } = useSelector((state) => state.financials);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>("day");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");

  const [columns, setColumns] = useState<IColum[]>([
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ]);
  const [reportType, setReportType] = useState<"cases" | "amount">("cases");

  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalStats,
      startDate,
      endDate,
      isAllTime,
      timeFrame,
      typeAccount,
      reportType,
    ],
    queryFn: async ({ signal }) => {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);
      query.append("reportType", reportType);

      const report = await BillingAPI.getWithdrawalStatsRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsSuccess(report.data.report));
      return report;
    },
    onError: () => {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalStatsFailed());
    },
  });

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };
  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  const handleChangeReportType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReportType(event.target.value as "cases" | "amount");
  };

  useEffect(() => {
    const dates = Array.from(new Set(withdrawal_stats.data.map((data) => data.period)));
    setColumns(buildColumns({ dates, reportType }));
    setReport(buildReport({ data: withdrawal_stats.data, dates }));
  }, [withdrawal_stats]);

  return (
    <div>
      <h2>Withdrawal stats</h2>
      <div className={classes.container}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame value={timeFrame} onChange={handleChangeTimeFrame} />
        </div>
        <div className={classes.typeContainer}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Report type</FormLabel>
            <RadioGroup row onChange={handleChangeReportType} value={reportType}>
              <FormControlLabel color="primary" value="cases" control={<Radio />} label="cases" />
              <FormControlLabel color="primary" value="amount" control={<Radio />} label="amount" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.accountTypeContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
      </div>

      <div>
        <Table
          csv_table_name="Withdrawal stats"
          columns={columns}
          loading={withdrawal_stats.loading}
          data={report}
          extractKey={(row) => row.plan}
        />
      </div>
    </div>
  );
};

export default WithdrawalStats;
