import React from "react";
import * as Yup from "yup";
import moment_tz from "moment-timezone";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./useStyles";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { APIFunds } from "../../../../api";
import { FormDatePicker } from "../../../../components/ui";

const onVocationUntilValidationSchema = Yup.object().shape({
  on_vacation_until: Yup.date()
    .typeError("Invalid date")
    .min(moment_tz(), "")
    .nullable()
    .default(null),
});

type THandleChangeOnVacation = (data: {
  on_vacation_until: null | MaterialUiPickersDate;
}) => Promise<void>;

const OnVocationUntilDateSelect = (): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { on_vacation_until } = useSelector((state) => state.fundedTrader);
  const handleChangeOnVacation: THandleChangeOnVacation = async (values) => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const endVocationDate = moment_tz(values.on_vacation_until)
        .tz("America/Chicago")
        .startOf("day")
        .format();
      const response = await APIFunds.updateFundedTraderDataRequest(id, {
        on_vacation_until: values.on_vacation_until ? endVocationDate : null,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error update on vacation date", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  const isOnVacation = moment_tz(on_vacation_until)
    .tz("America/Chicago")
    .isAfter(moment_tz().tz("America/Chicago"));

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnChange
        onSubmit={handleChangeOnVacation}
        initialValues={{ on_vacation_until: isOnVacation ? moment_tz(on_vacation_until) : null }}
        validationSchema={onVocationUntilValidationSchema}
      >
        {({ isValid, dirty }) => (
          <Form className={classes.formContainer}>
            <Field
              name="on_vacation_until"
              label="On Vacation Until:"
              component={FormDatePicker}
              datePickerProps={{
                minDate: moment_tz().add(1, "days"),
                size: "small",
                classes: {
                  root: classes.datePicker,
                },
              }}
            />
            <Button
              className={classes.savaButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OnVocationUntilDateSelect;
