import React, { useEffect, useState } from "react";
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useParams } from "react-router-dom";

import useGeneralStyles from "../../../../useGeneralStyles";
import {
  ContainerWithLoader,
  FilterAutocomplete,
  PaginationWithLimit,
  Select,
} from "../../../../components/ui";
import TableBody from "./TableBody";
import TableHead from "./TableHead";
import WithdrawalRequestForm from "../WithdrawalRequestForm";

import usePagination from "../../../../hooks/usePagination";
import { APIFunds } from "../../../../api";
import { IWithdrawalWithAccountInfo, TCompletedStatuses } from "../../../../api/funds/index.types";
import { EWithdrawalStatuses } from "../../../../constants";
import { WITHDRAWAL_FILTERS, WITHDRAWAL_STATUSES_OPTIONS } from "../../../Withdrawals/constants";

const WithdrawalsRequestList = (): React.ReactElement => {
  const controller = new AbortController();
  const { signal } = controller;

  const params: { id: string } = useParams();
  const id = +params.id;
  const generalClasses = useGeneralStyles();
  const { count, limit, setLimit, setPage, page, setCount } = usePagination();

  const [withdrawals, setWithdrawals] = useState<IWithdrawalWithAccountInfo[]>([]);
  const [editingWithdrawal, setEditingWithdrawal] = useState<IWithdrawalWithAccountInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState({
    by: "initialization_date",
    order: false,
  });
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState({
    status: EWithdrawalStatuses.pending,
  });

  const handleChangeFilter = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSearch({
      ...search,
      status: event.target.value as EWithdrawalStatuses,
    });
  };

  const fetchWithdrawalsList = async () => {
    try {
      setIsLoading(true);
      const response = await APIFunds.getWithdrawalsListRequest(
        {
          accounts_id: id,
          limit: +limit,
          page,
          sortBy,
          search,
        },
        signal
      );
      setWithdrawals(response.data.withdrawals.rows);
      setCount(response.data.withdrawals.count);
    } catch (error) {
      console.error("Error fetching withdrawals list", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (editingWithdrawal && !open) {
        await fetchWithdrawalsList();
      }
    })();
  }, [editingWithdrawal, open]);

  useEffect(() => {
    (async () => {
      await fetchWithdrawalsList();
    })();
    return () => {
      controller.abort();
    };
  }, [id, sortBy, limit, page, search]);

  const handleChangeWithdrawalStatus = async (
    e: React.MouseEvent,
    withdrawalId: number,
    status: TCompletedStatuses
  ) => {
    try {
      e.stopPropagation();
      await APIFunds.updateWithdrawalStatus({ id: withdrawalId, status, accounts_id: id });
      await fetchWithdrawalsList();
    } catch (error) {
      console.error("Error changing withdrawal status", error);
    }
  };

  const handleEditWithdrawal = (withdrawal: IWithdrawalWithAccountInfo) => {
    setOpen(true);
    setEditingWithdrawal(withdrawal);
  };
  const handleCancel = () => setOpen(false);

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Edit withdrawal request</DialogTitle>
        <DialogContent dividers>
          <WithdrawalRequestForm
            accounts_id={id}
            editValues={editingWithdrawal}
            onClose={handleCancel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} size="small" color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <b>Withdrawals requests list</b>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={3} md={3}>
          <Select
            inputLabel="Status"
            label="Status"
            value={search.status}
            onChange={handleChangeFilter}
            options={WITHDRAWAL_STATUSES_OPTIONS}
          />
        </Grid>
        <Grid item xs={9} md={9}>
          <FilterAutocomplete
            search={search}
            setSearch={setSearch}
            filters={WITHDRAWAL_FILTERS}
            inputTypes={{}}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <div className={generalClasses.tableHorizontalScroll}>
            <ContainerWithLoader
              report={withdrawals}
              isLoading={isLoading}
              noResultsMessage="There are no withdrawals"
            >
              <table className={generalClasses.table}>
                <TableHead sortBy={sortBy} setSortBy={setSortBy} />
                <TableBody
                  withdrawals={withdrawals}
                  handleChangeWithdrawalStatus={handleChangeWithdrawalStatus}
                  handleEditWithdrawal={handleEditWithdrawal}
                />
              </table>
            </ContainerWithLoader>
          </div>
          <div>
            <PaginationWithLimit
              count={count}
              limit={limit}
              page={page}
              setLimit={setLimit}
              setPageState={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WithdrawalsRequestList;
