import { FinancialsAction, FinancialsActionType } from "./index.types";
import {
  IGetTIFStatsResponse,
  IGetWithdrawalRatesResponse,
  ISalesStatsByDayResponse,
} from "../../api/billing/index.types";
import { IGetWithdrawalStatsResponse } from "../../api/billing/index.types";

export const FinancialsActions = {
  //SalesByDays
  fetchFinancialsSalesByDaysRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysRequest,
  }),
  fetchFinancialsSalesByDaysSuccess: (
    payload: ISalesStatsByDayResponse["data"]["report"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysSuccess,
    payload,
  }),
  fetchFinancialsSalesByDaysFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsSalesByDaysFailed,
  }),
  // Withdrawal stats
  fetchFinancialsWithdrawalStatsRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalStatsRequest,
  }),
  fetchFinancialsWithdrawalStatsSuccess: (
    payload: IGetWithdrawalStatsResponse["data"]["report"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalStatsSuccess,
    payload,
  }),
  fetchFinancialsWithdrawalStatsFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalStatsFailed,
  }),
  // TIF stats
  fetchFinancialsTIFStatsRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsTIFStatsRequest,
  }),
  fetchFinancialsTIFStatsSuccess: (
    payload: IGetTIFStatsResponse["data"]["report"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsTIFStatsSuccess,
    payload,
  }),
  fetchFinancialsTIFStatsFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsTIFStatsFailed,
  }),
  // Withdrawal per requests
  fetchFinancialsWithdrawalPerRequestsRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsRequest,
  }),
  fetchFinancialsWithdrawalPerRequestsSuccess: (
    payload: IGetWithdrawalRatesResponse["data"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsSuccess,
    payload,
  }),
  fetchFinancialsWithdrawalPerRequestsFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerRequestsFailed,
  }),
  // Withdrawal per TIF registration
  fetchFinancialsWithdrawalPerTIFRegistrationRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationRequest,
  }),
  fetchFinancialsWithdrawalPerTIFRegistrationSuccess: (
    payload: IGetWithdrawalRatesResponse["data"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationSuccess,
    payload,
  }),
  fetchFinancialsWithdrawalPerTIFRegistrationFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationFailed,
  }),
  // Withdrawal per Sales
  fetchFinancialsWithdrawalPerSalesRequest: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesRequest,
  }),
  fetchFinancialsWithdrawalPerSalesSuccess: (
    payload: IGetWithdrawalRatesResponse["data"]
  ): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesSuccess,
    payload,
  }),
  fetchFinancialsWithdrawalPerSalesFailed: (): FinancialsAction => ({
    type: FinancialsActionType.fetchFinancialsWithdrawalPerSalesFailed,
  }),
};
