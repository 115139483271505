import React, { useEffect } from "react";
import { Switch, FormControlLabel } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import momentTz from "moment-timezone";
import { useParams } from "react-router-dom";

import { APIFunds } from "../../../../api";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { useAdminProviderDataContext } from "../../../../context";

const ToggleStartNextMonth = (): React.ReactElement | null => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const dispatch = useDispatch();
  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();
  const { start_trading_date, accounts_id } = useSelector((state) => state.fundedTrader);

  const handleChangeStartNextMonth = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
      const response = await APIFunds.updateStartTradingDate(id, {
        isStartNexMonth: event.target.checked,
      });
      dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
    } catch (error) {
      console.error("Error handle handleChangeStartNextMonth", error);
      dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
    }
  };

  const isBeingTradingNextMonth = momentTz()
    .tz("America/Chicago")
    .isBefore(momentTz(start_trading_date).tz("America/Chicago"));

  useEffect(() => {
    (async () => {
      try {
        dispatch(FundedTraderActions.fetchFundedTraderDataRequest());
        const response = await APIFunds.getFundedTraderDataRequest(id);
        dispatch(FundedTraderActions.fetchFundedTraderDataSuccess(response.data));
      } catch (error) {
        console.error("Error handle get funded trader data", error);
        dispatch(FundedTraderActions.fetchFundedTraderDataFailed());
      }
    })();
  }, [id]);

  // If trader is not funded or agent don't have permission
  if (!accounts_id || !allowed_features.access_funded_traders) {
    return null;
  }

  return (
    <FormControlLabel
      label="Wants to start next month"
      control={
        <Switch
          size="small"
          checked={isBeingTradingNextMonth}
          onChange={handleChangeStartNextMonth}
          color="primary"
        />
      }
    />
  );
};

export default ToggleStartNextMonth;
