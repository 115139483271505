import React, { useState } from "react";
import { Tab, Tabs, Paper } from "@material-ui/core";

import { TABS_PROPS } from "./constants";
import * as Component from "./components";
import { TabPanel, TimeNow } from "../../components/ui";
import useGeneralStyles from "../../useGeneralStyles";

const Financials = (): React.ReactElement => {
  const generalClasses = useGeneralStyles();
  const [activeTabIndex, setActiveTab] = useState(0);
  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  return (
    <div>
      <div>
        <span>Based on Chicago time:</span> <TimeNow format="MM-DD-YYYY HH:mm" />
      </div>
      <div>
        <Paper square>
          <Tabs value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab label={TABS_PROPS.salesStats.label} value={TABS_PROPS.salesStats.value} />
            <Tab label={TABS_PROPS.countryStats.label} value={TABS_PROPS.countryStats.value} />
            <Tab label={TABS_PROPS.traderStats.label} value={TABS_PROPS.traderStats.value} />
            {/* <Tab 
              label={TABS_PROPS.salesAnalitics.label} 
              value={TABS_PROPS.salesAnalitics.value} 
            /> */}
            <Tab label={TABS_PROPS.disputes.label} value={TABS_PROPS.disputes.value} />
          </Tabs>
        </Paper>
      </div>

      <div className={generalClasses.containerBg}>
        <TabPanel value={activeTabIndex} index={TABS_PROPS.salesStats.value}>
          <Component.TabSalesStats />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={TABS_PROPS.countryStats.value}>
          <Component.TabCountryStats />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={TABS_PROPS.traderStats.value}>
          <Component.TabTraderStats />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={TABS_PROPS.salesAnalitics.value}>
          <Component.TabSalesAnalitics />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={TABS_PROPS.disputes.value}>
          <Component.TabDisputes />
        </TabPanel>
      </div>
    </div>
  );
};

export default Financials;
