import React, { useEffect, useState } from "react";
import { LineOptions } from "chart.js";
import { useSelector } from "react-redux";

import useStyles from "../TotalIncomeChart/useStyles";
import { skipped } from "../../../../../components/ui/ChartLine";
import { isWeekend } from "../../../../../utils";
import { ChartLine } from "../../../../../components/ui";
import { TABLE_PLANS } from "../ExportCsvProductsStats/constants";
import { COLOR_PALETTE } from "../../../../../constants";
import { CHART_OPTIONS } from "../constants";

interface IDataSet {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  tension: number;
  segment: Partial<LineOptions["segment"]>;
}
interface IProps {
  isIgnoreWeekends: boolean;
}
const SalesChartsAveragePrice: React.FC<IProps> = ({ isIgnoreWeekends }) => {
  const classes = useStyles();

  const { data: report } = useSelector((state: RootState) => state.financials.sales_by_days);
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<IDataSet[]>([]);

  useEffect(() => {
    const data: IDataSet[] = [];
    const labels = new Set();

    TABLE_PLANS.forEach((plan, index) => {
      const planSales: IDataSet = {
        label: plan,
        data: [],
        backgroundColor: COLOR_PALETTE[index + 20],
        borderColor: COLOR_PALETTE[index + 20],
        tension: 0.2,
        segment: {
          // @ts-ignore
          borderColor: (ctx) => skipped(ctx, index),
          // @ts-ignore
          borderDash: (ctx) => skipped(ctx, [2, 2]),
        },
        spanGaps: true,
      };
      report.forEach((item) => {
        const netNotUs = item[`${plan.toLowerCase()}_net_not_us` as keyof typeof item] as number;
        const netUs = item[`${plan.toLowerCase()}_net_us` as keyof typeof item] as number;

        const quantity = item[`${plan.toLowerCase()}_qt` as keyof typeof item] as number;
        const paid_resets = item[
          `${plan.toLowerCase()}_paid_reset_qt` as keyof typeof item
        ] as number;

        if (!isIgnoreWeekends) {
          labels.add(item.date);
          planSales.data.push((netNotUs + netUs) / (quantity + paid_resets));
        }

        if (isIgnoreWeekends && !isWeekend(new Date(item.date))) {
          labels.add(item.date);
          planSales.data.push((netNotUs + netUs) / (quantity + paid_resets));
        }
      });
      data.push(planSales);
    });

    setLabels(Array.from(labels) as string[]);
    setDatasets(data);
  }, [report, isIgnoreWeekends]);

  return (
    <div>
      <h3>Average prices</h3>

      <div className={classes.chartContainer}>
        <ChartLine data={{ labels, datasets }} externalOptions={CHART_OPTIONS} />
      </div>
    </div>
  );
};

export default SalesChartsAveragePrice;
