import React from "react";
import { useSelector } from "react-redux";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ConfirmDialog } from "../../../../components/ui";
import { FUNDS_API_URLS } from "../../../../api/funds";
import { APIFunds } from "../../../../api";

const ForcedToLive = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const params: { id: string } = useParams();
  const id = +params.id;
  const { is_forced_to_live } = useSelector((state) => state.fundedTrader);
  const [open, setOpen] = React.useState(false);

  const handleUpdateFundedTraderDataRequest = useMutation({
    mutationKey: [FUNDS_API_URLS.update_trader, id],
    mutationFn: () => {
      return APIFunds.updateFundedTraderDataRequest(id, { is_forced_to_live: !is_forced_to_live });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.get_funded_trader_data, id] });
      setOpen(false);
    },
  });

  const handleChangeForcedToLive = () => handleUpdateFundedTraderDataRequest.mutate();

  return (
    <div>
      <ConfirmDialog
        isOpen={open}
        dialogTitle="Forced to live"
        dialogContentText={`Are you sure you want to ${
          is_forced_to_live ? "disable" : "enable"
        } Forced Live for this trader?`}
        handleClickOk={() => handleChangeForcedToLive()}
        handleCancel={() => setOpen(false)}
      />
      <FormControlLabel
        label="Forced to live"
        control={
          <Checkbox
            size="small"
            onChange={() => {
              setOpen(true);
            }}
            checked={is_forced_to_live}
            name="is_forced_to_live"
            color="secondary"
          />
        }
      />
    </div>
  );
};

export default ForcedToLive;
