// USES ON: DISCOUNT CODES PAGE CREATE/EDIT/COPY MODE
// USES ON: CUSTOMER PROFILE PAGE CREATE NEW PERSONAL CODE
// USES ON: CUSTOMER PROFILE PAGE
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Paper, TextField, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as LogRocket from "logrocket";
import { Form, Formik, Field, FormikHelpers } from "formik";
import { useRouteMatch } from "react-router-dom";
import cx from "classnames";
import mt from "moment-timezone";

import { APIDiscounts } from "../../../api";
import { ISaveDiscountCodes, IUserEmailWithId } from "../../../api/discounts/index.types";
import { UserDiscountCodesActions } from "../../../reducers/userDiscountReducer";
import {
  useAdminProviderDataContext,
  useAuthContext,
  useSearchCustomersContext,
} from "../../../context";
import { MAX_LENGTH_DISCOUNT_CODE_DESCRIPTION } from "../../../constants";
import { DISCOUNT_CODE_VALIDATION_SCHEMA, getPlanId, getSuffix, INITIAL_CODE } from "./utils";

import NewPrice from "./NewPrice";
import Properties from "./Properties";
import { get_plan_options } from "./PlansOptions";
import CreateButtonGroup from "./CreateButtonGroup";
import BatchCreateNTimes from "./BatchCreateNTimes";

import InputWithRandomGeneratorString from "../InputWithRandomGeneratorString";
import Snackbar, { TTypeMessage } from "../Snackbar";
import { FormInput, FormSelect } from "../index";

import useStyles from "./useStyles";
import { ICode, IProps } from "./types";
import QuantityOptions from "./QuantityOptions";
import { DiscountCodesActions } from "../../../reducers/discountCodesReducer";
import downloadCsv from "../../../utils/downloadCsv";
import { CustomersActions } from "../../../reducers/customersReducer";
import { getCustomer } from "../../../pages/Customers/utils";
import DurationControls from "./DurationControls";

/* eslint-disable no-template-curly-in-string */
const EMAIL_PATTERN = "${email}";
/* eslint-disable no-template-curly-in-string */
const ID_PATTERN = "${id}";

const DiscountsModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  getDiscount,
  prefilledEmail,
  id,
  isEditingDiscountCode,
  selectedDiscountCode,
}) => {
  const classes = useStyles();
  const matchCustomersPage = useRouteMatch("/customers");
  const dispatch = useDispatch();

  const { hasExpired } = useAuthContext();
  const { user } = useAdminProviderDataContext();
  const searchCustomersContext = useSearchCustomersContext();

  const customers = useSelector((state) => state.customers.data);
  const { create_active_code, batch_create_codes } = user.allowed_features;
  const { plans } = useSelector((state) => state.plans.data);
  const discountCodes = useSelector((state) => state.discountCodes);

  const [open, setOpen] = useState<boolean>(false);
  const [linkUserQueryEmail, setLinkUserQueryEmail] = useState<string>("");
  const [status, setStatus] = useState<TTypeMessage>("");
  const [linkUserList, setLinkUserList] = useState<IUserEmailWithId[]>([]);
  const [isSavingCode, setIsSavingCode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const autoCompleteDefaultIdValue = id || selectedDiscountCode?.accounts_id;
  const autoCompleteDefaultEmailValue = prefilledEmail || selectedDiscountCode?.account.email;
  const isUsePrefilledAutoCompleteDefaultValue =
    prefilledEmail || selectedDiscountCode?.accounts_id;
  const [isOpenDownloadCSVDialog, setIsOpenDownloadCSVDialog] = useState(false);

  const CODE_INITIAL_STATE: ICode = {
    ...INITIAL_CODE,
    ...(id && { accounts_id: id }),
    ...(matchCustomersPage && {
      suffix: getSuffix(customers.count.toString()),
    }),
    active: create_active_code,
    isBatchCreate: false,
    ...(selectedDiscountCode?.code &&
      isOpen && {
        ...selectedDiscountCode,
        code: isEditingDiscountCode ? selectedDiscountCode?.code : "",
        descr: selectedDiscountCode.description || "",
        plan: getPlanId(!!selectedDiscountCode.reset, selectedDiscountCode.plan),
        qty: selectedDiscountCode.qty || -1,
        expires: selectedDiscountCode.expires || null,
        autoExpires: !!selectedDiscountCode.expires || false,
        percent: selectedDiscountCode.type === "percent",
        amount: +selectedDiscountCode.amt,
        autoExpiration: !!selectedDiscountCode.expiration || false,
        ...(selectedDiscountCode.expiration && {
          use_expiration: true,
          one_time: true,
        }),
        ...(selectedDiscountCode.expiration &&
          !isNaN(parseInt(selectedDiscountCode.expiration)) && {
            use_expiration_days: true,
            expiration_days: parseInt(selectedDiscountCode.expiration),
            expiration: null,
          }),
        ...(selectedDiscountCode.failover_type && {
          use_failover_amt: true,
          use_failover_type: true,
        }),
      }),
  };

  const handleLinkUserSearch = (target: string) => {
    if (target.length < 3) return; // don't query 1-2 letters
    setLinkUserQueryEmail(target);
  };

  const bulkAssignEmailsArray = (code: ICode) =>
    code?.bulkAssignEmails?.replace(/[\n,]/g, " ").trim().replaceAll("  ", " ").split(" ");

  const saveCodes = async (values: ICode, formHelpers: FormikHelpers<ICode>) => {
    setIsSavingCode(true);

    const { use_expiration_days, failover_type, failover_amt } = values;

    if (matchCustomersPage) {
      const getDescription = (email: string, id: number) =>
        values.descr.replace(EMAIL_PATTERN, email).replace(ID_PATTERN, id.toString());
      const codes: ISaveDiscountCodes[] = [];
      const isContainsPatternString =
        values.descr.includes(EMAIL_PATTERN) || values.descr.includes(ID_PATTERN);

      customers.allAccounts.forEach(({ id, email }, index) => {
        codes.push({
          code: `${values.code}${values.suffix[index]}`,
          accounts_id: id,
          desc: isContainsPatternString ? getDescription(email, id) : values.descr,
          amt: values.amount,
          qty: values.qty > 0 ? values.qty : null,
          plan_id: values.plan === -2 ? -1 : values.plan,
          type: values.percent ? "percent" : "flat",
          one_time: !values.one_time,
          active: values.active,
          expires: values.expires,
          reset: values.isReset || values.plan === -2,
          editableValueKeyName: "create_code",
          expiration: use_expiration_days
            ? values.expiration_days.toString()
            : values.expiration
            ? `${mt(values.expiration).format("MMM D")} 00:00:00 ${mt()
                .tz("America/Chicago")
                .format("zz")} ${mt(values.expiration).format("Y")}`
            : null,
          failover_type,
          failover_amt,
        });
      });

      try {
        const discountCodesResponse = await APIDiscounts.createBatchCodesRequest({
          codes,
          editableValueKeyName: "batch_create_code",
        });
        LogRocket.track("Discounts: Create");
        setIsOpenDownloadCSVDialog(true);
        setOpen(true);
        const responseData = discountCodesResponse.data.data;
        dispatch(DiscountCodesActions.fetchSuccess(responseData));
      } catch (error) {
        setStatus("error");
        setOpen(true);
      } finally {
        setIsSavingCode(false);
        formHelpers.resetForm();
      }
      return;
    }

    const data = {
      ...(isEditingDiscountCode && { code_id: selectedDiscountCode?.id }),
      code: values.suffix.length > 0 ? values.suffix.map((s) => values.code + s) : values.code,
      amt: values.amount,
      desc: values.descr,
      qty: bulkAssignEmailsArray(values)?.length || values.qty,
      plan_id: values.plan === -2 || values.plan === -1 ? null : values.plan,
      type: values.percent ? "percent" : "flat",
      one_time: !values.one_time,
      active: values.active,
      expires: values.expires,
      accounts_id: values.accounts_id,
      reset: values.plan === -2 ? true : null,
      editableValueKeyName: isEditingDiscountCode ? "update_code" : "create_code",
      bulkAssignEmails: bulkAssignEmailsArray,
      expiration: use_expiration_days
        ? values.expiration_days.toString()
        : values.expiration
        ? `${mt(values.expiration).format("MMM D")} 00:00:00 ${mt()
            .tz("America/Chicago")
            .format("zz")} ${mt(values.expiration).format("Y")}`
        : null,

      failover_type,
      failover_amt,
    };

    try {
      if (isEditingDiscountCode) {
        await APIDiscounts.updateDiscountCodeRequest(data);
        LogRocket.track("Update: Create");
      } else {
        await APIDiscounts.saveDiscountCodesRequest(data);
        LogRocket.track("Discounts: Create");
      }
      setStatus("success");
      setOpen(true);
      setIsOpen(false);
      getDiscount && getDiscount();

      if (prefilledEmail && id) {
        const response = await APIDiscounts.getCustomerDiscountCodesRequest({
          id,
          limit: "20",
          page: 0,
        });
        dispatch(UserDiscountCodesActions.fetchSuccess(response.data, id));
      }
    } catch (error) {
      const e = error as { response: { data: { message: string } } };
      setErrorMessage(e?.response?.data?.message || "");
      setStatus("error");
      setOpen(true);
    } finally {
      setLinkUserList([]);
      formHelpers.resetForm();
    }
    setIsSavingCode(false);
  };

  const handleDownloadCsv = () => {
    const HEADER = ["Code", "Description"];
    const rows: string[][] = [HEADER];
    discountCodes.data.discount_codes.forEach((discountCode) => {
      const { desc } = discountCode;
      rows.push([discountCode.code, desc.replaceAll(",", " ")]);
    });
    downloadCsv(rows, "Discounts for current users");

    dispatch(
      DiscountCodesActions.fetchSuccess({
        discount_codes: [],
        count: 0,
        usedDiscountCodesWithFullDiscount: [],
      })
    );
    setIsOpen(false);
    setIsOpenDownloadCSVDialog(false);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    setIsOpenDownloadCSVDialog(false);
  };

  const handleCloseSnackBar = () => {
    setOpen(false);
    setErrorMessage("");
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (linkUserQueryEmail.length < 3) return; // don't query 1-2 letters
      try {
        const response = await APIDiscounts.getEmailsListRequest({ email: linkUserQueryEmail });
        setLinkUserList(response.data);
      } catch (error) {
        console.error("Error getting emails", error);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [linkUserQueryEmail]);

  useEffect(() => {
    if (matchCustomersPage) {
      try {
        const controller = new AbortController();
        const { signal } = controller;

        dispatch(CustomersActions.fetchAllCustomersRequest());
        const customersData = async () => {
          const response = await getCustomer(
            searchCustomersContext,
            customers.count,
            hasExpired,
            customers,
            true,
            dispatch,
            signal,
            false
          );
          if (response) {
            dispatch(CustomersActions.fetchAllCustomersSuccess(response.accounts));
          }
        };
        if (isOpen) {
          customersData();
        }
      } catch (error) {
        CustomersActions.fetchAllCustomersFailed();
      }
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Formik
          enableReinitialize
          validateOnChange
          validateOnMount
          validateOnBlur
          initialValues={CODE_INITIAL_STATE}
          onSubmit={saveCodes}
          validationSchema={DISCOUNT_CODE_VALIDATION_SCHEMA}
        >
          {({ setFieldValue, values, isValid, submitForm, dirty }) => {
            return (
              <Form>
                <div className={classes.container}>
                  <BatchCreateNTimes
                    isSavingCode={isSavingCode}
                    submitForm={submitForm}
                    code={values}
                    setFieldValue={setFieldValue}
                  />

                  <div>
                    <h2 className={classes.title}>
                      {isEditingDiscountCode ? "Update code" : "Create code"}
                    </h2>
                  </div>
                  <div className={classes.leftContainer}>
                    <div className={classes.discountInputs}>
                      <InputWithRandomGeneratorString
                        label={matchCustomersPage ? "Code template" : "Code"}
                        name="code"
                        data-test="discount-code-input"
                        setFieldValue={setFieldValue}
                      />

                      {!matchCustomersPage && (
                        <Autocomplete
                          {...(autoCompleteDefaultIdValue &&
                            isUsePrefilledAutoCompleteDefaultValue && {
                              defaultValue: {
                                id: autoCompleteDefaultIdValue || -1,
                                email: autoCompleteDefaultEmailValue,
                              },
                            })}
                          disabled={!!(id && prefilledEmail)}
                          options={linkUserList}
                          // @ts-ignore
                          getOptionLabel={(option) => option.email}
                          onChange={(_, newValue) =>
                            setFieldValue("accounts_id", newValue ? newValue.id : null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Link to user"
                              onChange={(e) => handleLinkUserSearch(e.target.value)}
                            />
                          )}
                        />
                      )}

                      <div className={classes.planContainer}>
                        <Field
                          name="plan"
                          label="Plan"
                          placeholder="Plan"
                          component={FormSelect}
                          options={get_plan_options(plans)}
                          variant="standard"
                          data-test="plan-select"
                        />
                      </div>

                      <Field
                        name="amount"
                        component={FormInput}
                        data-test="amount-input"
                        label={values.percent ? "Percent" : "Discount amount"}
                        type="number"
                        variant="standard"
                        className={classes.amount}
                      />
                      <TextField
                        error={values.descr.length > MAX_LENGTH_DISCOUNT_CODE_DESCRIPTION}
                        label={matchCustomersPage ? "Description template" : "Description"}
                        value={values.descr}
                        onChange={(e) => {
                          setFieldValue("descr", e.target.value);
                        }}
                        helperText={`Please specify a description! ${
                          MAX_LENGTH_DISCOUNT_CODE_DESCRIPTION - values.descr.length
                        }/${MAX_LENGTH_DISCOUNT_CODE_DESCRIPTION}`}
                      />

                      <QuantityOptions code={values} setFieldValue={setFieldValue} />

                      <DurationControls values={values} setFieldValue={setFieldValue} />
                    </div>

                    <div>
                      <Paper variant="outlined" className={classes.rightContainer}>
                        <Properties
                          values={values}
                          isEditingDiscountCode={!!isEditingDiscountCode}
                          setFieldValue={setFieldValue}
                        />
                      </Paper>
                    </div>
                  </div>

                  <div className={classes.resultContainer}>
                    <div />
                    <div className={classes.textAlignRight}>
                      <NewPrice values={values} isNewPrice={!values.percent} />
                      <br />

                      <CreateButtonGroup
                        isValid={isValid}
                        dirty={dirty}
                        code={values}
                        isSavingCode={isSavingCode}
                        isAvailableBatchCreating={
                          isEditingDiscountCode ? false : batch_create_codes
                        }
                        isEditingDiscountCode={!!isEditingDiscountCode}
                        setFieldValue={setFieldValue}
                        data-test="create-button-group"
                      />
                    </div>
                  </div>

                  <Dialog open={isOpenDownloadCSVDialog} onClose={handleCloseDialog}>
                    <div className={cx(classes.container, classes.containerCenter)}>
                      <h3>Codes made successfully</h3>
                      <Button variant="contained" color="primary" onClick={handleDownloadCsv}>
                        Download codes
                      </Button>
                    </div>
                  </Dialog>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>

      <Snackbar
        type={status}
        isOpenSnackbar={open}
        responseSuccessMessage="Success, code created!"
        responseErrorMessage={`Error: ${errorMessage}`}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default DiscountsModal;
