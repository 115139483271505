import React from "react";

import PerRequestTable from "./PerRequestTable";
import PerTifRegistration from "./PerTifRegistration";
import PerSales from "./PerSales";

const WithdrawalRates: React.FC = () => {
  return (
    <div>
      <h2>Withdrawal rates</h2>
      <PerRequestTable />
      <PerTifRegistration />
      <PerSales />
    </div>
  );
};

export default WithdrawalRates;
