import React from "react";

import TableDataCellWithSort from "../../../../components/ui/TableDataCellWithSort";
import { TBy } from "../../../../reducers/customersReducer";
import { useSearchCustomersContext } from "../../../../context";
import newSortBy from "../../../../utils/newSortBy";

const TableHeader = (): React.ReactElement => {
  const { sortBy, relatedTables, setSortBy } = useSearchCustomersContext();

  const handleSortBy = (by: TBy) => newSortBy({ by, sortBy, setSortBy });

  return (
    <thead>
      <tr>
        <TableDataCellWithSort
          sortBy={sortBy}
          by="id"
          title="IID"
          newSortBy={handleSortBy}
          isReset={sortBy.by !== "id" || sortBy.order}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by='upper(left("name", 1))'
          title="Name"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by='upper(left("email", 1))'
          title="Email"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by="joined"
          title="Joined"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by='"country.name"'
          title="Country"
          newSortBy={handleSortBy}
        />
        {relatedTables.includes("stateprov") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by="stateprov"
            title="State Prov Reg"
            newSortBy={handleSortBy}
          />
        )}

        <TableDataCellWithSort
          sortBy={sortBy}
          by='"account_plans.plan.name"'
          title="Active Plan"
          newSortBy={handleSortBy}
        />
        <TableDataCellWithSort
          sortBy={sortBy}
          by='"account_plans.expires"'
          title="Next Bill"
          newSortBy={handleSortBy}
        />
        {relatedTables.includes("last4") && <td>Last 4 digits</td>}
        {relatedTables.includes("billing_histories") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by='"billing_histories.total"'
            title="Billing history"
            newSortBy={handleSortBy}
          />
        )}
        {relatedTables.includes("account_notes") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by='"account_notes.note"'
            title="Account notes"
            newSortBy={handleSortBy}
          />
        )}
        {relatedTables.includes("account_agreements") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by='"account_agreements.status"'
            title="Account agreement"
            newSortBy={handleSortBy}
          />
        )}
        {relatedTables.includes("account_flags") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by='"account_flags.flag"'
            title="Account flags"
            newSortBy={handleSortBy}
          />
        )}
        {relatedTables.includes("free_reset") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by="free_reset"
            title="Free reset"
            newSortBy={handleSortBy}
          />
        )}
        {relatedTables.includes("phone") && (
          <TableDataCellWithSort
            sortBy={sortBy}
            by="phone"
            title="Phone number"
            newSortBy={handleSortBy}
          />
        )}
      </tr>
    </thead>
  );
};

export default TableHeader;
