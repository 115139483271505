import React from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { Select } from "../../../../components/ui";
import { FUNDS_API_URLS, FundsAPI } from "../../../../api/funds";
import { IFundedTrader } from "../../../../api/funds/index.types";

const PEP_VALUES = [
  {
    name: "no",
    text: "No",
  },
  {
    name: "not_pep",
    text: "Not PEP, but was asked",
  },
  {
    name: "pep",
    text: "PEP (Politically Exposed Person)",
  },
];

const PEPSelect = (): React.ReactElement | null => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const queryClient = useQueryClient();
  const { pep, isLoading } = useSelector((state) => state.fundedTrader);

  const handleUpdateFundedTraderPEPStatus = useMutation({
    mutationKey: [FUNDS_API_URLS.update_trader, id],
    mutationFn: (pep: IFundedTrader["pep"]) => {
      return FundsAPI.updateFundedTraderDataRequest(id, { pep });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FUNDS_API_URLS.get_funded_trader_data, id] });
    },
  });
  const handleChangePEPStatus = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown | string }>
  ) => {
    handleUpdateFundedTraderPEPStatus.mutate(event.target.value as IFundedTrader["pep"]);
  };

  if (isLoading) {
    return null;
  }
  return (
    <div>
      <Select
        options={PEP_VALUES}
        label="Politically Exposed Person"
        inputLabel="Politically Exposed Person"
        onChange={handleChangePEPStatus}
        value={pep || "no"}
        size="small"
      />
    </div>
  );
};

export default PEPSelect;
