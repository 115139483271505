import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Button } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { PaginationWithLimit } from "../../../../components/ui";
import AddNoteForm from "../AddNoteForm";
import usePagination from "../../../../hooks/usePagination";

import useStyles from "./useStyles";
import { FundedTraderActions } from "../../../../reducers/fundedTraderReducer/actions";
import { APIFunds } from "../../../../api";
import FundedTraderNotes from "../FundedTraderNotes";

const FundedProfileNotesTable = (): React.ReactElement => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const id = +params.id;
  const classes = useStyles();
  const { limit, setLimit, setPage, page } = usePagination("5");

  const { count } = useSelector((state) => state.fundedTrader.notes);
  const [open, setOpen] = useState(false);

  const fetchFundedTraderNotes = async (): Promise<void> => {
    try {
      dispatch(FundedTraderActions.fetchFundedTraderNotesRequest());
      const response = await APIFunds.getFundedAccountNotesRequest(id, { limit: +limit, page });
      dispatch(FundedTraderActions.fetchFundedTraderNotesSuccess(response));
    } catch (error) {
      console.error("Error fetching funded account notes");
      dispatch(FundedTraderActions.fetchFundedTraderNotesFailed());
    }
  };

  const handleExpand = () => setOpen(!open);

  useEffect(() => {
    (async () => {
      await fetchFundedTraderNotes();
    })();
  }, [limit, page]);

  useEffect(() => {
    setPage(0);
  }, [limit]);

  return (
    <div className={classes.notesContainer}>
      <Button size="small" variant="contained" onClick={handleExpand}>
        Notes:
        {open ? (
          <ExpandLess className={classes.hover} />
        ) : (
          <ExpandMore className={classes.hover} data-test="expand-notes" />
        )}
      </Button>
      {/*<b className={classes.title}>Notes</b>*/}
      <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapseContainer}>
        <div className={classes.tableBorder}>
          <AddNoteForm fetchFundedTraderNotes={fetchFundedTraderNotes} />

          <FundedTraderNotes fetchFundedTraderNotes={fetchFundedTraderNotes} />
          <div>
            <PaginationWithLimit
              count={count}
              limit={limit}
              page={page}
              setLimit={setLimit}
              setPageState={setPage}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default FundedProfileNotesTable;
