import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import cx from "classnames";

import { IColum } from "../../../../../utils/copyToClipboardInXlsFormat";
import { buildColumns, IReport, buildReport } from "../../utils";
import Table from "../../../../ComplianceSearch/components/Table";
import useStyles from "../useStyles";
import SelectByLiveSimOrLive from "../../../../../components/ui/SelectByLiveSimOrLive";
import SelectByTimeFrame from "../../../../../components/ui/SelectByTimeFrame";
import { BillingAPI } from "../../../../../api/billing";
import { TTimeFrame } from "../../../../../components/ui/SelectByTimeFrame";
import { TTypeAccount } from "../../../../../components/ui/SelectByLiveSimOrLive";

import { BILLING_API_URLS } from "../../../../../api/billing";
import { FinancialsActions } from "../../../../../reducers/financialsReducer/actions";
import SelectByDateWithCustomPeriod from "../../../../../components/ui/SelectByDateWithCustomPeriod";
import { DATES_VALUE } from "../../../../../constants";

const PerRequestTable: React.FC = () => {
  const classes = useStyles();
  const { data, loading } = useSelector((state) => state.financials.withdrawal_per_requests);

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>("day");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");

  const [columns, setColumns] = useState<IColum[]>([
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ]);
  const [report, setReport] = useState<IReport[]>([]);

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [
      BILLING_API_URLS.withdrawalRates,
      "withdrawal_per_requests",
      startDate,
      endDate,
      isAllTime,
      timeFrame,
      typeAccount,
    ],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);

      const response = await BillingAPI.getWithdrawalRatesRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsSuccess(response.data));
      return response;
    },
    onError() {
      console.error("Error fetching withdrawal rates");
      dispatch(FinancialsActions.fetchFinancialsWithdrawalPerRequestsFailed());
    },
  });

  useEffect(() => {
    const dates = Array.from(new Set(data.w80.map(({ period }) => period)));
    setColumns(buildColumns({ dates, reportType: "amount", isUseChangeToPrev: false }));

    const reportWithWithdawalPerRequest = data.w80.map((report) => ({
      ...report,
      account_size: "",
      total_amount: report.total_amount / report.total_count,
    }));

    setReport(
      buildReport({ data: reportWithWithdawalPerRequest, dates, options: { isShowTotal: false } })
    );
  }, [data]);

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };

  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  return (
    <div>
      <h3 className={classes.tableNameTitle}>Withdrawal per requests</h3>

      <div className={cx(classes.container, classes.tableNameTitle)}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame value={timeFrame} onChange={handleChangeTimeFrame} />
        </div>
        <div className={classes.timeFrameContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
      </div>

      <Table
        csv_table_name="Withdrawal per requests"
        columns={columns}
        loading={loading}
        data={report}
        extractKey={(row) => row.plan}
      />
    </div>
  );
};

export default PerRequestTable;
