import produce from "immer";

import { FinancialsAction, FinancialsActionType, IFinancialsState } from "./index.types";

export const initialState: IFinancialsState = {
  sales_by_days: {
    data: [],
    loading: false,
  },
  withdrawal_stats: {
    data: [],
    loading: false,
  },
  tif_stats: {
    data: [],
    loading: false,
  },
  withdrawal_per_requests: {
    data: {
      billingHistory: [],
      tifRegistration: [],
      w80: [],
    },
    loading: false,
  },
  withdrawal_per_tif_registration: {
    data: {
      billingHistory: [],
      tifRegistration: [],
      w80: [],
    },
    loading: false,
  },
  withdrawal_per_sales: {
    data: {
      billingHistory: [],
      tifRegistration: [],
      w80: [],
    },
    loading: false,
  },
};

const financialsReducer = (prevState = initialState, action: FinancialsAction): IFinancialsState =>
  produce(prevState, (draft: IFinancialsState): IFinancialsState => {
    switch (action.type) {
      //SalesByDays
      case FinancialsActionType.fetchFinancialsSalesByDaysRequest:
        draft.sales_by_days.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsSalesByDaysSuccess:
        draft.sales_by_days.data = action.payload;
        draft.sales_by_days.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsSalesByDaysFailed:
        draft.sales_by_days.loading = false;
        break;
      // Withdrawal stats
      case FinancialsActionType.fetchFinancialsWithdrawalStatsRequest:
        draft.withdrawal_stats.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalStatsSuccess:
        draft.withdrawal_stats.data = action.payload;
        draft.withdrawal_stats.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalStatsFailed:
        draft.withdrawal_stats.loading = false;
        break;
      // TIF stats
      case FinancialsActionType.fetchFinancialsTIFStatsRequest:
        draft.tif_stats.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsTIFStatsSuccess:
        draft.tif_stats.data = action.payload;
        draft.tif_stats.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsTIFStatsFailed:
        draft.tif_stats.loading = false;
        break;
      // Withdrawal per requests
      case FinancialsActionType.fetchFinancialsWithdrawalPerRequestsRequest:
        draft.withdrawal_per_requests.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerRequestsSuccess:
        draft.withdrawal_per_requests.data = action.payload;
        draft.withdrawal_per_requests.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerRequestsFailed:
        draft.withdrawal_per_requests.loading = false;
        break;
      // Withdrawal per TIF registration
      case FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationRequest:
        draft.withdrawal_per_tif_registration.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationSuccess:
        draft.withdrawal_per_tif_registration.data = action.payload;
        draft.withdrawal_per_tif_registration.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerTIFRegistrationFailed:
        draft.withdrawal_per_tif_registration.loading = false;
        break;
      // Withdrawal per Sales
      case FinancialsActionType.fetchFinancialsWithdrawalPerSalesRequest:
        draft.withdrawal_per_sales.loading = true;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerSalesSuccess:
        draft.withdrawal_per_sales.data = action.payload;
        draft.withdrawal_per_sales.loading = false;
        break;
      case FinancialsActionType.fetchFinancialsWithdrawalPerSalesFailed:
        draft.withdrawal_per_sales.loading = false;
        break;
    }
    return draft;
  });

export default financialsReducer;
