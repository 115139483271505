import React from "react";
import * as LogRocket from "logrocket";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { Plan } from "../../Customer";
import { APIIntercom } from "../../../../api";
import { useCustomerProvider } from "../../../../context";
import { ACCOUNTS_API_URLS } from "../../../../api/accounts";
import { CENTS_IN_DOLLAR } from "../../../../constants";

interface IProps {
  editingPlan: Plan;
  editingPlanSaveStatus: string;
  setEditingPlan: (plan?: Plan) => void;
  setEditingPlanSaveStatus: (s: string) => void;
}

const UpdatePlanConfirmWindow: React.FC<IProps> = ({
  editingPlan,
  editingPlanSaveStatus,
  setEditingPlan,
  setEditingPlanSaveStatus,
}) => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const queryClient = useQueryClient();

  const { customer } = useCustomerProvider();
  const { data } = useSelector((state) => state.customer.plans);
  const saveEditingPlan = async () => {
    setEditingPlanSaveStatus("Please wait...");

    try {
      const current_edit_plan = data.plans.find(({ id }) => editingPlan.id === id);
      await APIIntercom.saveEditingPlanRequest(customer.xid, {
        id: editingPlan.id,
        expires: editingPlan.expires,
        previous_expires: current_edit_plan ? current_edit_plan.expires : editingPlan.expires,
        price_usd: editingPlan.price_usd * CENTS_IN_DOLLAR,
        previous_price_usd: current_edit_plan
          ? current_edit_plan.price_usd * CENTS_IN_DOLLAR
          : editingPlan.price_usd * CENTS_IN_DOLLAR,
        status: editingPlan.status,
        accounts_id: customer.id,
      });

      setEditingPlanSaveStatus("Success!");
      LogRocket.track("Customer: Change Rebill Data");

      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans, id, 0],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_API_URLS.accountPlans],
      });
      setTimeout(() => {
        setEditingPlan();
      }, 2000);
    } catch (error) {
      setEditingPlanSaveStatus("Error!");
      console.error(error);
      setTimeout(() => {
        setEditingPlan();
      }, 2000);
    }
  };

  return (
    <div className="popup popup-save">
      <div className="title">Are you sure?</div>
      Are you sure you want to update this plan to the following?
      <br />
      <br />
      <b>Active: </b>
      {editingPlan.status === "active" ? "Yes" : "No"}
      <br />
      <b>Rebill Date: </b>
      {editingPlan.expires}
      <br />
      <b>Price: </b>${editingPlan.price_usd}
      <div style={{ padding: "10px 0" }}>{editingPlanSaveStatus}</div>
      <button type="button" onClick={() => setEditingPlan()}>
        Cancel
      </button>
      <button
        type="button"
        onClick={() => saveEditingPlan()}
        className="yes"
        data-test="button-save-intercom-profile"
      >
        Update
      </button>
    </div>
  );
};

export default UpdatePlanConfirmWindow;
