import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  styled,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";

import { APIURMA } from "../../../api";
import { ICreateURMAProfileData } from "../../../api/urma/index.types";
import { LEGAL_TYPE_OPTIONS, PROFILE_TYPE_OPTIONS, URMA_PROFILE_SCHEMA } from "../constants";
import { TTypeMessage } from "../../ui/Snackbar";
import { useURMAContext } from "../../../context";
import { FormInput, FormSelect, FormChipInput, Snackbar } from "../../ui";

const StyledFrom = styled(Form)({ marginTop: 10 });

interface IProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  profile: ICreateURMAProfileData;
}
const CreateURMAProfileModal = ({ open, setOpen, profile }: IProps): React.ReactElement => {
  const { setUrmaProfile } = useURMAContext();
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    resultType: "" as TTypeMessage,
    responseSuccessMessage: "Created",
    responseSuccessErrorMessage: "",
  });
  const handleCloseSnackBar = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const [isFetching, setFetching] = useState(false);
  const handleClose = () => setOpen(false);
  const handleSubmit = async (values: ICreateURMAProfileData) => {
    setFetching(true);
    try {
      const response = await APIURMA.createURMAProfileRequest(values);
      setSnackBarState({
        ...snackBarState,
        resultType: "success",
        open: true,
      });
      setUrmaProfile(response.data);
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (error) {
      const e = error as { response: { data: { message: string } } };
      setSnackBarState({
        ...snackBarState,
        resultType: "error",
        responseSuccessErrorMessage: e.response.data.message,
        open: true,
      });
    } finally {
      setFetching(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Create profile</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={profile}
          onSubmit={handleSubmit}
          validationSchema={URMA_PROFILE_SCHEMA}
        >
          {({ isValid }) => (
            <StyledFrom>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field name="name" label="Name" placeholder="Name" component={FormInput} />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="legal_type"
                    label="Legal type"
                    placeholder="Legal type"
                    component={FormSelect}
                    options={LEGAL_TYPE_OPTIONS}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="contact.first_name"
                    label="First name"
                    placeholder="First name"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="contact.last_name"
                    label="Last name"
                    placeholder="Last name"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    name="contact.emails"
                    label="Emails"
                    placeholder="Emails"
                    component={FormChipInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Field
                    name="contact.phones"
                    label="Phones"
                    placeholder="Phones"
                    component={FormChipInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="contact.faxes"
                    label="Faxes"
                    placeholder="Faxes"
                    component={FormChipInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.name"
                    label="Name of an address"
                    placeholder="Name of an address"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.zip"
                    label="ZIP"
                    placeholder="ZIP"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.country"
                    label="Country"
                    placeholder="Country"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.state"
                    label="State"
                    placeholder="State"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.city"
                    label="City"
                    placeholder="City"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.address_line1"
                    label="Address line 1"
                    placeholder="Address line 1"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Field
                    name="contact.addresses.address_line2"
                    label="Address line 2"
                    placeholder="Address line 2"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="profile_type"
                    label="profile type"
                    placeholder="profile type"
                    component={FormSelect}
                    options={PROFILE_TYPE_OPTIONS}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    contextualHelpText="CQG specific parameter."
                    name="number"
                    label="Number"
                    placeholder="Number"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <div>
                <DialogActions>
                  <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid || isFetching}
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    Create
                  </Button>
                </DialogActions>
              </div>
            </StyledFrom>
          )}
        </Formik>
      </DialogContent>
      <Snackbar
        type={snackBarState.resultType}
        isOpenSnackbar={snackBarState.open}
        responseSuccessMessage={snackBarState.responseSuccessMessage}
        responseErrorMessage={snackBarState.responseSuccessErrorMessage}
        onClose={handleCloseSnackBar}
      />
    </Dialog>
  );
};

export default CreateURMAProfileModal;
