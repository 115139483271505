import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { IFinamark } from "../../pages/Customer/Customer";
import { APIAccounts, APIEvaluation, APIIntercom } from "../../api";
import { IBillingHistories } from "../../api/accounts/index.types";
import { IFundedTraderWidthAccountData } from "../../api/funds/index.types";
import { IEvaluationsSteps, IRithmicView } from "../../api/evaluations/index.types";
import { checkisPassedEvaluation } from "../../pages/Customer/utils";
import { CustomerActions } from "../../reducers/customerReducer/actions";
import { ACCOUNTS_API_URLS } from "../../api/accounts";

interface IPlan {
  id: number;
  start?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  [key: string]: any;
}
export interface ICustomer {
  address: string;
  address2: string;
  account_notes: IPlan[];
  account_agreements: [{ status: string; [key: string]: string }];
  country: {
    id: number;
    name: string;
    iso2: string;
    timezone?: {
      timezones: string[];
    };
  };
  campaign_property_id: boolean | null;
  city: string;
  countries_id: number;
  display_name: string | null;
  email: string;
  id: number;
  joined: string;
  motivation: string | null;
  name: string;
  phone: string;
  reg_ip: string;
  stateprov: string;
  status: number;
  stripe_customer: string;
  verification_key: string;
  xid: string;
  zip: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  intercom?: { [key: string]: any };
  planSum?: number;
  account_flags: string[];
  billing_histories: IBillingHistories[];
  funded_trader: IFundedTraderWidthAccountData | null;
  isPassedEvaluation: boolean;
  evaluation_steps: IEvaluationsSteps | null;
}
export interface ICustomerProps {
  customer: ICustomer;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setCustomer: (a: any) => void;
  getCustomer: () => Promise<void>;
  fina: IFinamark;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFina: (a: any) => void;
  loading: {
    customer: number;
    intercom: number;
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setLoading: (a: any) => void;
  customerEvaluation: IRithmicView;
  isPassedEvaluation: boolean;
  evaluation_steps: IEvaluationsSteps | null;
  setEvaluationSteps: (steps: IEvaluationsSteps) => void;
}

const CUSTOMER_INITIAL_STATE: ICustomer = {
  address: "",
  address2: "",
  account_agreements: [{ status: "n/a" }],
  account_notes: [],
  billing_histories: [],
  country: {
    id: -1,
    name: "",
    iso2: "",
  },
  campaign_property_id: null,
  city: "",
  countries_id: 1,
  display_name: null,
  email: "",
  id: 0,
  joined: "",
  motivation: null,
  name: "",
  phone: "",
  reg_ip: "",
  stateprov: "",
  status: 1,
  stripe_customer: "",
  verification_key: "",
  xid: "",
  zip: "",
  account_flags: [],
  funded_trader: null,
  isPassedEvaluation: false,
  evaluation_steps: {
    id: -1,
    account: "",
    accounts_id: -1,
    confirm_passed: false,
    certificated_design: false,
    certificated_email: false,
    welcoming_process: false,
  },
  // banned_account: null,
};
const CUSTOMER_EVALUATION_INITIAL_STATE = {
  acc_id: "",
  days_traded: 0,
  email: "",
  account_name: "",
  pnl: 0,
  consistency: 0,
  largest_pnl: 0,
  largest_pnl_percent: 0,
  full_count: 0,
  profit_goal: -1,
  starting_capital: 0,
  acc_balance: 0,
  timestamp: "",
  plans_key: "",
};
const CustomerContext = createContext<ICustomerProps>({
  customer: CUSTOMER_INITIAL_STATE,
  setCustomer: () => ({}),
  // @ts-ignore
  getCustomer: () => ({}),
  fina: {
    days: 60,
    user: "",
    pass: "",
    sys: "paper",
    gway: "americas",
    id: "",
  },
  setFina: () => ({}),
  loading: {
    customer: 0,
    intercom: 0,
  },
  setLoading: () => ({}),
  customerEvaluation: CUSTOMER_EVALUATION_INITIAL_STATE,
  isPassedEvaluation: false,
  evaluation_steps: CUSTOMER_INITIAL_STATE.evaluation_steps,
  setEvaluationSteps: () => ({}),
});

interface IProps {
  children: React.ReactNode;
  customerData?: Record<string, unknown>;
}
const CustomerProvider = ({ children, customerData }: IProps): React.ReactElement => {
  const params: { id: string } = useParams();
  const id = +params.id;
  const dispatch = useDispatch();
  const [customerEvaluation, setCustomerEvaluation] = useState<IRithmicView>(
    CUSTOMER_EVALUATION_INITIAL_STATE
  );
  const [customer, setCustomer] = useState<ICustomer>(CUSTOMER_INITIAL_STATE);
  const [fina, setFina] = useState<IFinamark>({
    days: 60,
    user: "",
    pass: "",
    sys: "paper",
    gway: "americas",
    id: "",
  });
  const [loading, setLoading] = useState({
    customer: 0,
    intercom: 0,
  });
  const [isPassedEvaluation, setIsPassedEvaluation] = useState(false);
  const [evaluation_steps, setEvaluationSteps] = useState<IEvaluationsSteps | null>(null);
  const getICCustomer = async (xid: string) => {
    setLoading({ ...loading, intercom: 1 });

    try {
      const response = await APIIntercom.getICCustomerRequest(xid);
      setLoading({ ...loading, intercom: 2 });

      setCustomer((prevState: ICustomer) => ({ ...prevState, intercom: { ...response.data } }));
      setFina({
        ...fina,
        user:
          response.data && response.data.custom_attributes["Gauntlet Account Name"]?.split("gau")[0]
            ? response.data.custom_attributes["Gauntlet Account Name"]?.split("gau")[0]
            : "",
        id: response.data.id,
      });
    } catch (error) {
      setLoading({ ...loading, intercom: 3 });
      console.error(error);
    }
  };

  const getCustomer = useQuery({
    retry: false,
    queryKey: [ACCOUNTS_API_URLS.accountsExtend(id)],
    async queryFn() {
      dispatch(CustomerActions.fetchCustomerMainDataRequest());
      const { data } = await APIAccounts.getCustomerRequest(id);
      dispatch(CustomerActions.fetchCustomerMainDataSuccess(data));

      const { billing_histories = [] } = data;
      let planSum = 0;
      billing_histories.forEach((plan) => {
        planSum += plan.price_usd;
      });
      // @ts-ignore
      setCustomer({
        ...data,
        ...(customer.intercom && { intercom: customer.intercom }),
        billing_histories: billing_histories.sort((a, b) => a.id - b.id),
        // account_plans: [{ id: 0 }], // set default account plan -- nullreference
        planSum: planSum / 100,
      });
      return data;
    },
    onError(error) {
      console.error(error);
      dispatch(CustomerActions.fetchCustomerMainDataFailed());
    },
  });

  useEffect(() => {
    if (customer.xid) {
      getICCustomer(customer.xid);
    }
  }, [customer.xid]);

  useEffect(() => {
    const isPassedEvaluation = checkisPassedEvaluation({
      largest_pnl_percent: customerEvaluation.largest_pnl_percent,
      pnl: customerEvaluation.pnl,
      profit_goal: customerEvaluation.profit_goal,
      days_traded: customerEvaluation.days_traded,
    });
    setIsPassedEvaluation(isPassedEvaluation);
  }, [customerEvaluation]);

  const e2tId = customer.intercom?.custom_attributes?.["Gauntlet Account Name"];
  useEffect(() => {
    if (e2tId) {
      (async () => {
        try {
          const { data } = await APIEvaluation.getCustomerEvaluationsRequest(e2tId, customer.email);

          if (data.evaluation_steps) {
            setEvaluationSteps(data.evaluation_steps);
          }
          const lastReport = data.report.at(-1);
          if (lastReport) {
            setCustomerEvaluation(lastReport);
          }
        } catch (error) {
          console.error("Error fetching evaluations data");
        }
      })();
    }
  }, [e2tId]);

  return (
    <CustomerContext.Provider
      value={{
        customer: {
          ...customer,
          ...customerData,
        },
        setCustomer,
        // @ts-ignore
        getCustomer: getCustomer.refetch,
        fina,
        setFina,
        loading,
        setLoading,
        customerEvaluation,
        isPassedEvaluation,
        evaluation_steps,
        setEvaluationSteps,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
export const useCustomerProvider = (): ICustomerProps => useContext(CustomerContext);
