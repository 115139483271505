import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { FinancialsActions } from "../../../../reducers/financialsReducer/actions";
import { BillingAPI, BILLING_API_URLS } from "../../../../api/billing";
import SelectByDateWithCustomPeriod from "../../../../components/ui/SelectByDateWithCustomPeriod";
import SelectByTimeFrame, { TTimeFrame } from "../../../../components/ui/SelectByTimeFrame";
import { DATES_VALUE } from "../../../../constants";
import SelectByLiveSimOrLive, {
  TTypeAccount,
} from "../../../../components/ui/SelectByLiveSimOrLive";
import useStyles from "./useStyles";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import Table from "../../../ComplianceSearch/components/Table";
import { IReport, buildColumns, buildReport } from "../utils";

const TIFStats: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isAllTime, setIsAllTime] = useState(false);
  const [timeFrame, setTimeFrame] = useState<TTimeFrame>("day");
  const [typeAccount, setTypeAccount] = useState<TTypeAccount>("all");

  const [report, setReport] = useState<IReport[]>([]);
  const [columns, setColumns] = useState<IColum[]>([
    {
      by: "plan",
      title: "Plan",
      path: "plan",
    },
  ]);

  const { data, loading } = useSelector((state) => state.financials.tif_stats);

  useQuery({
    enabled: !!startDate || !!endDate,
    queryKey: [BILLING_API_URLS.tifStats, startDate, endDate, isAllTime, timeFrame, typeAccount],
    async queryFn({ signal }) {
      dispatch(FinancialsActions.fetchFinancialsTIFStatsRequest());
      const query = new URLSearchParams();
      query.append("startDate", startDate);
      query.append("endDate", endDate);
      query.append("isAllTime", String(isAllTime));
      query.append("timeFrame", timeFrame);
      query.append("account_type", typeAccount);

      const response = await BillingAPI.getTIFStatsRequest(query.toString(), signal);
      dispatch(FinancialsActions.fetchFinancialsTIFStatsSuccess(response.data.report));
      return response;
    },
    onError() {
      console.error("Error fetching TIF stats");
      dispatch(FinancialsActions.fetchFinancialsTIFStatsFailed());
    },
  });

  const handleChangeTimeFrame = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTimeFrame(event.target.value as TTimeFrame);
  };

  const handleChangeTypeAccount = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeAccount(event.target.value as TTypeAccount);
  };

  useEffect(() => {
    const dates = Array.from(new Set(data.map((data) => data.period)));
    setColumns(buildColumns({ dates }));
    setReport(buildReport({ data, dates }));
  }, [data]);

  return (
    <div>
      <h2>TIF stats</h2>
      <div className={classes.container}>
        <div>
          <SelectByDateWithCustomPeriod
            setIsLoading={() => void null}
            getReport={(startDate, endDate, isAllTime) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setIsAllTime(!!isAllTime);
            }}
            setReport={() => void null}
            initialDateBy={DATES_VALUE.thisWeek}
          />
        </div>

        <div className={classes.timeFrameContainer}>
          <SelectByTimeFrame value={timeFrame} onChange={handleChangeTimeFrame} />
        </div>
        <div className={classes.timeFrameContainer}>
          <SelectByLiveSimOrLive value={typeAccount} onChange={handleChangeTypeAccount} />
        </div>
      </div>

      <div>
        <Table
          csv_table_name="TIF stats"
          columns={columns}
          loading={loading}
          data={report}
          extractKey={(row) => row.plan}
        />
      </div>
    </div>
  );
};

export default TIFStats;
