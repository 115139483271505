import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { APIAccounts } from "../../../../api";

import PlansTable from "./components/PlansTable";
import Pagination from "../../../../components/ui/Pagination";
import { CustomerActions } from "../../../../reducers/customerReducer/actions";
import { ACCOUNTS_API_URLS } from "../../../../api/accounts";

const UserPlans = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();

  const [plansPage, setPlansPage] = useState(0);

  const {
    data: { count },
    loading,
  } = useSelector((state) => state.customer.plans);

  useQuery({
    retry: false,
    queryKey: [ACCOUNTS_API_URLS.accountPlans, id, plansPage],
    async queryFn() {
      dispatch(CustomerActions.fetchCustomerPlansRequest());
      const response = await APIAccounts.getCustomerPlansRequest(id, plansPage);
      dispatch(CustomerActions.fetchCustomerPlansSuccess(response.data));

      return response;
    },
    onError() {
      dispatch(CustomerActions.fetchCustomerPlansFailed());
    },
  });

  return (
    <div>
      <b>User Plans</b>
      <PlansTable />
      <Pagination
        page={plansPage}
        _setPage={setPlansPage}
        isLoading={loading}
        count={count}
        limit={5}
      />
    </div>
  );
};

export default UserPlans;
