import React from "react";
import { Grid } from "@material-ui/core";

import SalesBreakdownPerCountry from "../SalesBreakdownPerCountry";
import SalesPerCountryLineChart from "../SalesPerCountryLineChart";
// import TIFRegistrationByLocation from "../TIFRegistrationByLocation";

const TabCountryStats: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <SalesBreakdownPerCountry />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SalesPerCountryLineChart />
        </Grid>
      </Grid>
      {/* <Grid item xs={6}>
        <TIFRegistrationByLocation />
      </Grid> */}
    </Grid>
  );
};

export default TabCountryStats;
